import React, { memo } from "react";

const DecorFilter = () => {
  return (
    <div className="my-3">
      <p className="font-medium lg:text-[1rem] xl:text-[1.1rem] my-2">
        Decor Category
      </p>
    </div>
  );
};

export default memo(DecorFilter);
