import axios from "axios";

/**
 * for getting the offers from database
 * @param {Object} {  BaseUrl,
  queryObject,
  skip,
  limit,sortingObj}
 * @return {Object}
 */
const get_offers_by_query_object = async ({
  BaseUrl,
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/offers/get_offers_by_query_object`,
      {
        queryObject,
        skip,
        limit,
        sortingObj,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

export { get_offers_by_query_object };
