import React, { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import CompRenderingContext from "../../context/CompRenderingContext";

/**
 use case
 const [states,setStates] = useState({
  ...other states info,
   isDeletionOtpModal: false
 })

 <DeletionOtpModal
 parentStates={states}
 setParentStates={setStates}
 />


 setStates(p=> ({...p, 
   isDeletionOtpModal: true
    }))

 */

export default function DeletionOtpModal({
  setParentStates,
  handleDelete,
  parentStates,
}) {
  return (
    <AnimatePresence>
      {parentStates.isDeletionOtpModal && (
        <Modal
          setParentStates={setParentStates}
          parentStates={parentStates}
          handleDelete={handleDelete}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setParentStates, parentStates, handleDelete }) => {
  const { setWarningModal } = useContext(CompRenderingContext);
  const handleClose = () =>
    setParentStates((p) => ({
      ...p,
      isDeletionOtpModal: false,
      otp: "",
    }));

  return (
    <div
      style={{ zIndex: 15 }}
      className="fixed top-0 right-0 left-0 bottom-0 text-[1rem] flex items-center justify-center "
    >
      <div onClick={handleClose} className="partial-black-background"></div>
      <motion.div
        initial={{
          translateY: 200,
          opacity: 0,
        }}
        animate={{
          translateY: 0,
          opacity: 1,
        }}
        exit={{
          translateY: 200,
          opacity: 0,
        }}
        transition={{ ease: "easeInOut" }}
        className=" max-w-[25rem] bg-white w-[95%] p-4 rounded-xl relative z-[2]  flex flex-col "
      >
        <div className="w-full font-[500] flex items-center  justify-between border-b pb-1 mb-2 ">
          User deletion process
          <RxCross2
            onClick={handleClose}
            className=" text-[1.25rem] cursor-pointer"
          />
        </div>{" "}
        <div className="text-[1rem] ">
          We have sent an OTP to {parentStates.phoneNumber}
        </div>
        {/* phone number input */}
        <input
          className="border rounded-xl p-2 mt-2 w-[20rem]"
          type="number"
          value={parentStates.otp}
          onChange={(e) =>
            setParentStates((p) => ({ ...p, otp: e.target.value }))
          }
          placeholder="Enter OTP"
        />
        <div
          onClick={() => {
            setWarningModal({
              text: "Are you sure? This process is not reversible",
              isRender: true,
              funToBeExecuted: async () => {
                const res = await handleDelete();
                if (res) {
                  handleClose();
                }
              },
              funToBeExecutedOnNo: () => {},
            });
          }}
          className="border rounded-xl py-1 text-[red] w-[15rem]  cursor-pointer flex items-center
         justify-center border-[red] bg-[#ffe2e2] mt-4"
        >
          {parentStates.isLoading ? (
            <div
              style={{
                borderTopColor: "red",
              }}
              className="loader  w-[1rem] my-1 "
            ></div>
          ) : (
            "Delete account permanently"
          )}
        </div>
      </motion.div>
    </div>
  );
};
