import React, { memo, useContext } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import ScrollHistoryContext from "../../../../context/ScrollHistoryContext";

const KwpHeader = ({ screenWidth }) => {
  const { removeFromHistory } = useContext(ScrollHistoryContext);
  const navigate = useNavigate();

  const { keyword } = useParams();
  if (screenWidth > 769) {
    return <></>;
  } else {
    return (
      <div className=" w-[100vw] pl-3 h-[3rem] items-center justify-between flex border-b border-gray-200 bg-white z-50 fixed top-0 left-0 right-0 ">
        <div
          onClick={() => {
            const lastUrl = removeFromHistory();
            navigate(lastUrl.url);
          }}
          className="flex items-center cursor-pointer relative top-[1px]  gap-2  "
        >
          <BsChevronLeft className="text-[1.25rem] text-[black]" />

          <h1
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className=" text-[2rem] text-[#2136d4] font-[800]  "
          >
            ebo
          </h1>
        </div>
      </div>
    );
  }
};

export default memo(KwpHeader);
