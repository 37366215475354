import React, { createContext, useEffect, useState } from "react";

const ResponsiveContext = createContext();

export const ResponsiveProvider = ({ children }) => {
  const [componentMounted, isComponentMounted] = useState(false);

  const initialScreenWidth = componentMounted ? window.innerWidth : 700; // Set a reasonable default width

  const [screenWidth, setScreenWidth] = useState(initialScreenWidth);

  const [colors] = useState({
    primary: "#2136D4",
  });

  const [background] = useState({
    lightsky: "#F0F5FE",
  });

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    isComponentMounted(true);
    setScreenWidth(initialScreenWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ResponsiveContext.Provider value={{ screenWidth, colors, background }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveContext;
