import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import ResponsiveContext from "../../context/ResponsiveContext";
import { EBO_PHONE_NUMBER } from "../../utils/constants";

export default function FloatingWhatsapp({ pageName, productCode }) {
  const text = "Book on Chat";
  const { user } = useContext(AuthContext);
  const { screenWidth } = useContext(ResponsiveContext);

  let position = {
    bottom:
      screenWidth <= 768 && (pageName === "home" || pageName === "product")
        ? "5rem"
        : "3rem",
  };

  const content = {
    home: {
      content: user
        ? `Hi, I'm ${user.name}, I need help regarding product selection.`
        : "Hi, I'm new at ebo, I need help regarging product selection.",
    },
    products: {
      content: user
        ? `Hi, I'm ${user.name}, I need help regarding product selection.`
        : "Hi, I'm new at ebo, I need help regarding product selection.",
    },
    product: {
      content: user
        ? `Hi, I'm ${user.name}, I'm trying to book a product whose product code is ${productCode}.`
        : `Hi, I'm new at ebo, I'm trying to book a product whose product code is ${productCode}.`,
    },
  };

  return (
    <div
      style={position}
      className=" gap-[6px]  fixed -right-[6.9rem] hover:right-[.5rem] z-[5] px-3 py-2 bg-[#31a73f] text-[white]  shadow-md  rounded-full flex items-center justify-start cursor-pointer duration-500  "
    >
      <FaWhatsapp className="text-[2rem] relative bottom-[1px] text-[white] " />
      <Link
        to={`https://wa.me/${EBO_PHONE_NUMBER}?text=${content[pageName].content}`}
        target="_blank"
        className="text-[1rem] text-[white] hover:text-[white] "
      >
        {text}
      </Link>
    </div>
  );
}

// return (
//   <div
//     style={position}
//     className=" gap-2  fixed right-[1rem] z-[5]  bg-[white]  shadow-md  rounded-full border flex items-center justify-start p-2 cursor-pointer w-[2.5rem] duration-500 overflow-hidden  h-[2.5rem] hover:w-[9em] "
//   >
//     <FaWhatsapp className="text-[2rem] w-[1.5rem] min-w-[1.5rem] text-[#4FCE5D] " />
//     <Link
//       to={`https://wa.me/${number}?text=${content[pageName].content}`} target="_blank"
//       className="text-[#171717] flex items-center min-w-[9rem] pr-1 md:text-[1rem] text-[.925rem] "
//     >
//       {text}
//     </Link>
//   </div>
// );
