import React, { memo } from "react";
import {
  BsInstagram,
  BsFacebook,
  BsLinkedin,
  BsFillHeartFill,
  BsTwitterX,
} from "react-icons/bs";
import { Link } from "react-router-dom";

function FooterRight() {
  return (
    <div className="footer-right">
      <div className="footer-right-cont">
        <div className=" flex items-center justify-center flex-col ">
          <h1
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className=" md:relative absolute md:-left-1 top-4 left-4   py-4  text-center 
            text-4xl md:text-5xl text-[#2136d4] font-bold"
          >
            ebo
          </h1>

          <p className="footer-right-sub-cont2-p ">
            Share your memories!
            <BsFillHeartFill />
          </p>
          <div className="footer-right-social flex justify-between">
            <Link to="https://www.instagram.com/ebo.now/" target="_blank">
              <BsInstagram />
            </Link>
            <Link to="https://www.facebook.com/ebo.nowfb" target="_blank">
              <BsFacebook />
            </Link>
            <Link to="https://www.linkedin.com/company/ebonow/" target="_blank">
              <BsLinkedin />
            </Link>
            <Link to="https://twitter.com/ebocares" target="_blank">
              <BsTwitterX />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(FooterRight);
