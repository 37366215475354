import React from "react";
import { Link } from "react-router-dom";
import { IoLogoAppleAppstore } from "react-icons/io5";
import { IoLogoGooglePlaystore } from "react-icons/io5";

export default function DownloadAppPoster2() {
  return (
    <div className="pl-2 md:hidden py-2 md:pl-4 md:py-4 w-full z-[1]  text-[.875rem] md:text-[1rem]  border rounded-[.5rem] flex overflow-hidden relative">
      <div className="w-full">
        <p className=" flex relative z-[2] items-baseline gap-[2px] whitespace-nowrap font-semibold text-[1rem] md:text-[1rem]">
          <span
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className="text-[1.75rem] md:text-[2.75rem] text-[#2136D4] font-[900] mr-[4px]"
          >
            ebo
          </span>
          is better on the app. Try now!
        </p>

        <p className="w-[70%] pl-[2px] z-[2] relative  leading-[18px] text-[gray] ">
          Book service faster with better experience on
          <span
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className="text-[1rem] text-[#2136d4] font-[900] mx-[4px]"
          >
            ebo
          </span>
          app
        </p>

        <Link to="https://ebo.onelink.me/HcT2/tws2pnj0" className="">
          <div className=" mt-4 blue-gradient rounded-[30px] text-[1rem] w-fit   text-center py-[8px] px-4  flex items-center gap-2 whitespace-nowrap">
            <p>Download the app</p>
            <div className="flex items-center gap-1">
              <IoLogoGooglePlaystore className="text-white text-[1.5rem]" />
              <IoLogoAppleAppstore className="text-white text-[1.5rem]" />
            </div>
          </div>
        </Link>
      </div>
      <div className="absolute -right-[22px] -bottom-[2.25rem] md:-bottom-[4.5rem] md:-right-[2rem]">
        <img
          src="https://img.ebonow.com/Posters/Appdownload.webp"
          alt=""
          className=" h-[170px] w-[145px] md:h-[280px] md:w-[200px] object-contain"
        />
      </div>
    </div>
  );
}
