import React from "react";

export default function MoreAddressInfo({ formData, setFormData }) {
  return (
    <>
      {/* <div className=" text-[.825rem] mt-4 text-[grey]  ">Address line 1</div> */}
      <input
        type="text"
        value={formData.address1}
        onChange={(e) =>
          setFormData((p) => ({
            ...p,
            address1: e.target.value,
          }))
        }
        className="p-3 border text-[.825rem] mt-4  rounded-md"
        placeholder="Flat no. / House No. / Building name"
      />
      {/* <div className=" text-[.825rem]  mt-2 text-[grey]  ">Address line 2</div> */}
      <input
        type="text"
        value={formData.address2}
        onChange={(e) =>
          setFormData((p) => ({
            ...p,
            address2: e.target.value,
          }))
        }
        className="p-3 border mt-4 text-[.825rem] rounded-md"
        placeholder="Locality name / Land mark"
      />
    </>
  );
}
