import React, { useContext, useEffect, useState } from "react";
import OfferContext from "../../context/OfferContext";
import Spinner from "../../assets/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
// --------------- icons ------------------------------
import { BiSolidOffer } from "react-icons/bi";
import { FaBolt } from "react-icons/fa";
import { HiHeart } from "react-icons/hi";
import CompRenderingContext from "../../context/CompRenderingContext";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import GLobalStoreContext from "../../context/GlobalStoreContext";
import { MdNotListedLocation } from "react-icons/md";

export default function ProductCard({
  index,
  product,
  width,
  min_width,
  onProductClickCallBack,
  inPreviewCardCallBack,
}) {
  // ------------------------ hooks ------------------------------

  const navigate = useNavigate();
  const cardRef = React.useRef(null);

  const [inPreview, setInPreview] = useState(false);

  useEffect(() => {
    if (inPreview) {
      inPreviewCardCallBack && inPreviewCardCallBack(index, product);
    }

    // eslint-disable-next-line
  }, [inPreview]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if card is intersecting with the viewport
        if (entry.isIntersecting) {
          setInPreview(true);
        } else {
          setInPreview(false);
        }
      },
      {
        threshold: 0.5,
        rootMargin: "-100px 0px 0px 0px",
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    // Cleanup
    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef?.current);
      }
    };
  }, []);

  // -------------- contexts ------------------------------
  const { toggleWishlistItem, wishlistedArray } =
    useContext(GLobalStoreContext);
  const { setToastMsg, setIsLoginModal, locationData, setLocationData } =
    useContext(CompRenderingContext);
  const { user } = useContext(AuthContext);
  const { offerState } = useContext(OfferContext);
  // ---------------------- states --------------------
  const [leastPrice, setLeastPrice] = useState(null);
  const [isWishlistingSpinner, setIsInWishlistSpinner] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(false);

  const productPriceDetail = product.listingPriceHubWise?.find(
    (currHub) => currHub.hub === locationData?.selectedLocation?.hubId
  );

  // -------------------- useEffect --------------------
  useEffect(() => {
    const find_least_price = async () => {
      const { calc_least_price } = await import(
        "../../functions/calculateLowesPrice"
      );
      const leastPriceRes = calc_least_price(
        productPriceDetail?.sellingPrice,
        offerState.offers
      );

      setLeastPrice(leastPriceRes);
    };
    find_least_price();
    //eslint-disable-next-line
  }, [productPriceDetail, offerState.offers]);

  useEffect(() => {
    setIsInWishlist(false);
    wishlistedArray.forEach((curr) => {
      product._id === curr && setIsInWishlist(true);
    });
    // eslint-disable-next-line
  }, [wishlistedArray]);

  const isOnBooking = product?.tags?.includes("on-demand booking");

  // ------------------------------functions ------------------------------
  const onAddToWishlist = async () => {
    if (!user) {
      setToastMsg({
        msg: "Please login first",
        isRender: true,
      });
      setIsLoginModal(true);

      return;
    }
    setIsInWishlistSpinner(true);
    isInWishlist
      ? await toggleWishlistItem(product, "remove")
      : await toggleWishlistItem(product, "add");
    setTimeout(() => {
      setIsInWishlistSpinner(false);
    }, 500);
  };

  const onProductClick = () => {
    navigate(
      `/products/decor/${product._id}/${product.name
        .trim()
        .replace("&", "and")
        .split(" ")
        .join("-")}`
    );
    onProductClickCallBack && onProductClickCallBack();
  };

  if (!locationData?.selectedLocation?.hubId) {
    return (
      <CardWhenNoLocation
        product={product}
        width={width}
        cardRef={cardRef}
        min_width={min_width}
        onProductClick={onProductClick}
        setLocationData={setLocationData}
      />
    );
  }

  if (productPriceDetail) {
    return (
      <div
        style={{
          width: width,
          minWidth: min_width,
        }}
        ref={cardRef}
        className={`flex flex-col relative `}
      >
        <div
          onClick={() => {
            !isWishlistingSpinner && onAddToWishlist();
          }}
          className="flex rounded-[50%] text-[1rem]  absolute w-[1.3rem] h-[1.3rem] bg-white shadow-md right-1 top-1 items-center justify-center"
        >
          {isWishlistingSpinner ? (
            <div className="relative right-[.3rem] bottom-[.8rem]">
              <Spinner size={10} />
            </div>
          ) : isInWishlist ? (
            <HiHeart className={` text-[#2136d4] rounded-full`} />
          ) : (
            <HiHeart
              className={` text-[#b4b4b4ab] hover:scale-[1.2] hover:text-[#b5c0ffab] transition-[all] rounded-full  `}
            />
          )}
        </div>
        <LazyLoadImage
          src={product?.images[0]}
          alt={product.name}
          className={`w-full aspect-square border border-[#e6e6e6] rounded-lg `}
          loading="lazy"
          width="100%"
          height="100%"
          title={product.name}
          placeholderSrc="/img/placeholder.svg"
          onClick={() => {
            onProductClick();
          }}
        />

        <div
          onClick={() => {
            onProductClick();
          }}
          className="flex leading-5 tracking-[.6px] flex-col px-2 pt-1 pb-2"
        >
          <p className=" h-auto md:max-h-[2.5rem] max-h-[2.5rem] min-h-[2rem] overflow-hidden    md:text-[.875rem] text-[.8125rem] font-[500]  text-[#000000] ">
            {product.name}
          </p>
          {isOnBooking ? (
            <>
              <p className="flex gap-3 mt-2 font-[500] text-[.825rem] items-center">
                *Price on request
              </p>
              <p className="mirror-Animation font-[500] before:w-[20px] bg-gradient-to-r  from-blue-50 to-white rounded-[15px] p-1 px-2  mt-[2px] relative right-[4px] flex items-center gap-1  text-[.75rem] text-[#2136d4]  md:text-[.825rem]">
                Enquire for price
              </p>
            </>
          ) : (
            <>
              {leastPrice?.discount > 0 ? (
                <>
                  <p className="flex gap-2 mt-2 whitespace-nowrap text-[.825rem] items-center">
                    <span className="flex items-center font-[600]  text-[#000000] ">
                      ₹{leastPrice?.leastPrice}
                    </span>
                    <span className="flex items-center font-[400]  text-[gray] line-through ">
                      ₹{productPriceDetail?.sellingPrice}
                    </span>
                    <span className="flex items-center text-[#00a807] font-[400]">
                      ₹{leastPrice?.discount} off
                    </span>
                  </p>
                  <div
                    className="mirror-Animation w-[105%] whitespace-nowrap before:w-[20px] bg-gradient-to-r pl-2 py-1 from-blue-50 to-white rounded-l-[15px]  font-[400] mt-[2px] relative right-[11px]
               flex items-center gap-[2px] text-[11px] text-[#2136d4]  md:text-[.8125rem]"
                  >
                    <FaBolt className="text-[#2136d4] text-[9px]  animate-offer-icon " />
                    Get at
                    <span className=" font-[600] ml-[1px] ">
                      ₹{leastPrice?.leastPrice}
                    </span>
                    | Use
                    <span className=" font-[600] ">
                      {leastPrice?.offerCode}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <p className="flex gap-2 mt-2 whitespace-nowrap text-[.875rem] items-center">
                    <span className="flex items-center font-[600]  text-[#000000] ">
                      ₹{productPriceDetail?.sellingPrice}
                    </span>
                    <span className="flex items-center font-[400]  text-[gray] line-through ">
                      ₹{productPriceDetail?.costPrice}
                    </span>
                    <span className="flex items-center text-[#00a807] font-[400]">
                      {productPriceDetail?.webDiscount}% off
                    </span>
                  </p>
                  <div
                    className="mirror-Animation w-[105%] whitespace-nowrap before:w-[20px] bg-gradient-to-r pl-2 py-1 from-blue-50 to-white rounded-l-[15px]  font-[400]   relative right-[11px]
               flex items-center justify-start gap-[2px] text-[12px] text-[#2136d4]  md:text-[.8125rem]"
                  >
                    <BiSolidOffer className="text-[#2136d4] text-[14px] relative bottom-[1px] mr-[2px]  animate-offer-icon " />
                    More offers at checkout
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const CardWhenNoLocation = ({
  product,
  width,
  min_width,
  setLocationData,
  onProductClick,
  cardRef,
}) => {
  return (
    <div
      style={{
        width: width,
        minWidth: min_width,
      }}
      className={`flex flex-col`}
      ref={cardRef}
    >
      <div className="flex flex-col w-full   overflow-hidden rounded-lg">
        <LazyLoadImage
          src={product?.images[0]}
          alt={product.name}
          className={`w-full aspect-square border border-[#e6e6e6] rounded-lg `}
          loading="lazy"
          width="100%"
          height="100%"
          title={product.name}
          placeholderSrc="/img/placeholder.svg"
          onClick={onProductClick}
        />

        <div className="flex leading-5 tracking-[.6px] flex-col mb-1 px-2">
          <p
            onClick={onProductClick}
            className=" h-auto md:max-h-[2.5rem] max-h-[2.5rem] min-h-[2rem] overflow-hidden    md:text-[.875rem] text-[.8rem] font-[500]  text-[#000000] "
          >
            {product.name}
          </p>
        </div>
        <div
          onClick={() =>
            setLocationData((p) => ({
              ...p,
              isLocationAddModalRender: true,
            }))
          }
          className=" mirror-Animation before:w-[10px] bg-gradient-to-l from-blue-50 to-white justify-between rounded-r-full flex mirror-Animation bg-[white] mt-1 items-center pr-2 mx-2 mb-2 py-2 gap-1"
        >
          <div className="flex flex-col whitespace-nowrap ">
            <div className="text-[1rem] leading-4 text-[#2136d4] font-[500]">
              Set location
            </div>
            <div className="text-[.75rem] text-[grey] leading-[15px]">
              To view price
            </div>
          </div>
          <MdNotListedLocation className="text-[#2136d4] text-[1.8rem]" />
        </div>
      </div>
    </div>
  );
};
