import React, { useContext, memo } from "react";
import { Link } from "react-router-dom";
import ProductPreviewContext from "../../../context/ProductPreviewContext";
import HomePageContext from "../../../context/HomepageContext";

function CategoriesList({ handelCloseOfContainer }) {
  const { resetPagePositions } = useContext(ProductPreviewContext);

  return (
    <div className="search-popular-categories flex flex-col">
      <h3 className="search-popular-categories-h3 text-[#565050] text-[1.2rem]">
        Popular Categories
      </h3>
      <Cards
        handelCloseOfContainer={handelCloseOfContainer}
        resetPagePositions={resetPagePositions}
      />
    </div>
  );
}

const Cards = memo(({ handelCloseOfContainer, resetPagePositions }) => {
  const { homeInitialData } = useContext(HomePageContext);

  const data = homeInitialData?.initialData?.homePageTopCategories;

  return (
    <div
      style={{ rowGap: ".2rem" }}
      className="grid gap-[1rem] md:gap-[3rem] w-[95%] md:w-[90%] md:max-w-[35rem] mt-[1.5rem]  grid-cols-4 "
    >
      {data.map((currCat) => {
        return (
          <Link
            to={currCat.redirectingUrl}
            key={currCat._id}
            onClick={() => {
              resetPagePositions();
              handelCloseOfContainer();
            }}
            style={{ boxShadow: "0 0 2px #d9d9d9 " }}
            className={`flex rounded-lg overflow-hidden items-center col-span-${currCat.cardsCapture} justify-center gap-2 cursor-pointer`}
          >
            <img
              src={currCat.bannerImgUrl}
              className="w-full"
              alt={currCat.name}
              loading="lazy"
              title={currCat.name}
            />
          </Link>
        );
      })}
    </div>
  );
});

export default memo(CategoriesList);
