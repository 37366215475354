import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL;

const update_address_bq = async ({ updateObj, queryObj }) => {
  // update address by query object and upating object
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.post(
      `${url}/api/users/address/update-address-bq`,
      { updateObj, queryObj },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error.message);
    return {
      isSuccess: false,

      message: error.message,
    };
  }
};
const get_address_bq = async ({ projectionString, queryObj }) => {
  // update address by query object and upating object
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.post(
      `${url}/api/users/address/get-address-bq`,
      { projectionString, queryObj: { ...queryObj, deleted: false } },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error.message);
    return {
      isSuccess: false,

      message: error.message,
    };
  }
};
const add_address_v2_api = async (addressData) => {
  // update address by query object and upating object
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.post(
      `${url}/api/users/address/save-address`,
      { addressData },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error.message);
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

export { update_address_bq, get_address_bq, add_address_v2_api };
