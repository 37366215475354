import React, { memo } from "react";
import "./Footer.css";
import FooterBottom from "./FooterBottom";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import FootMoreAboutUs from "./FootMoreAboutUs";
import GetItFooter from "../../assets/mobileAppAssets/GetItFooter";
import ServicLocations from "./ServicLocations";

function Footer() {
  return (
    <footer className="w-full flex bg-[#f0f5fe] pt-[1rem] md:pt-[2rem] flex-col">
      <ServicLocations />
      <div className="ebo-footer w-full">
        <div className="-my-6 md:hidden -mt-4">
          <GetItFooter />
        </div>
        <FooterLeft />
        <FooterRight />
      </div>
      <div className="  h-[6rem] pl-4 bg-[#f0f5fe] hidden md:block">
        <GetItFooter />
      </div>
      <FooterBottom />
      <FootMoreAboutUs />
    </footer>
  );
}

export default memo(Footer);
