import React, { memo } from "react";

const OcassionFilter = () => {
  return (
    <div className="my-3">
      <p className="font-medium mb-1 lg:mb-0 lg:text-[1rem] xl:text-[1.1rem] my-2">
        Occasion Category
      </p>
    </div>
  );
};

export default memo(OcassionFilter);
