import { useState, useEffect, useRef } from "react";

export const useScrollDirection = (targetRef) => {
  const [scrollStates, setScrollStates] = useState({
    scrollDir: "Up",
    scrollDistance: 0,
    isScrolling: false,
  });
  const prevScrollY = useRef(0);
  const scrollTimeout = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      if (!targetRef.current) return; // Handle potential undefined ref
      const scrollY = targetRef.current.scrollTop; // Access scrollTop of scrollable element
      const newScrollDir = scrollY > prevScrollY.current ? "Up" : "Down";
      const distance = Math.abs(scrollY - prevScrollY.current);
      setScrollStates((p) => ({
        ...p,
        scrollY: scrollY,
        scrollDir: newScrollDir,
        scrollDistance: distance,
        isScrolling: true,
      }));

      prevScrollY.current = scrollY;

      // Clear previous timeout
      clearTimeout(scrollTimeout.current);

      // Set a timeout to mark scrolling as false after 300ms (adjust this value as needed)
      scrollTimeout.current = setTimeout(() => {
        setScrollStates((prevState) => ({
          ...prevState,
          isScrolling: false,
        }));
      }, 300);
    };

    if (targetRef.current) {
      // Add check before attaching listener
      targetRef?.current?.addEventListener("scroll", onScroll);
    }

    return () => {
      if (targetRef.current) {
        // Clean up listener when unmounting
        targetRef?.current?.removeEventListener("scroll", onScroll);
      }
      // Clear timeout on unmount
      clearTimeout(scrollTimeout.current);
    };
  }, [targetRef]); // Add targetRef as dependency

  return { scrollStates };
};
