import React, { memo } from "react";
import PopUpComponents from "./PopUpComponents";
import EachRoute from "./EachRoute";
import "../components/homeComponents/EboHome.css";
import useFetchEboData from "../hooks/useFetchEboData";
import NavbarLg from "../components/navbar/NavbarLg.jsx";
import MetaHelmet from "../helmet/MetaHelmet.jsx";

const MainRouter = ({ initialDataFromServer }) => {
  useFetchEboData();

  return (
    <div className="bg-[white]">
      <PopUpComponents />
      <NavbarLg />
      <MetaHelmet />
      <EachRoute initialDataFromServer={initialDataFromServer} />
    </div>
  );
};

export default memo(MainRouter);
