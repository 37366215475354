import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveContext from "../../context/ResponsiveContext";

export default function ProductListBanner({ productListingpageBanners }) {
  const nav = useNavigate();
  const { screenWidth } = useContext(ResponsiveContext);
  return (
    <div
      onClick={() => {
        const url = productListingpageBanners?.topbanner?.redirectingUrl;
        if (url && url !== "") {
          if (url.startsWith("https://")) {
            // External URL, navigate to a different domain
            window.location.href = url;
          } else {
            // Internal URL, change the pathname within your application
            nav(url);
          }
        }
      }}
      className=" flex items-center justify-center w-[95%]   min-w-[95%]"
    >
      <img
        src={productListingpageBanners?.topbanner?.bannerImgUrl}
        alt="ebo now offer banner"
        loading="lazy"
        title="ebo now offer banner"
        style={
          screenWidth > 600 ? { aspectRatio: 6 / 1 } : { aspectRatio: 4 / 1 }
        }
        className="w-[100%] border border-[#ebebeb] rounded-md"
      />
    </div>
  );
}
