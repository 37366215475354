import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AuthContext from "./AuthContext";
import moment from "moment";

const TempAuthContext = createContext();

export const TempAuthProvider = ({ children }) => {
  const [componentMounted, isComponentMounted] = useState(false);
  useEffect(() => {
    isComponentMounted(true);
    return () => {
      isComponentMounted(false);
    };
  }, []);
  const LC_TEMP = componentMounted
    ? JSON.parse(localStorage?.getItem("tempuser"))
    : null;

  const [tempUserState, setTempUserState] = useState({
    tempUser: LC_TEMP ? LC_TEMP : null,
    isLoading: false,
    isError: false,
    isSucess: false,
    message: "",
  });

  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const {
    initialState: { user },
  } = useContext(AuthContext);

  const { tempUser } = tempUserState;

  const BASE_URL = REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (tempUser && tempUser?.createTime) {
      const expiresIn = moment.duration(168, "hours"); // 168 hours

      if (
        moment().valueOf() >
        moment(tempUser?.createTime).valueOf() + expiresIn
      ) {
        removeTempUser();
      }
    }
  }, [tempUser]);

  const createTempUser = async () => {
    if (tempUser || user) return;

    console.log("creating temp user");

    const uid = uuidv4();

    setTempUserState((prev) => ({ ...prev, isLoading: true }));
    try {
      const res = await axios.post(`${BASE_URL}/api/users/create-temp-user`, {
        uid,
      });
      window && localStorage.setItem("tempuser", JSON.stringify(res.data));
      setTempUserState((prev) => ({
        ...prev,
        isLoading: false,
        isSucess: true,
        tempUser: res.data,
      }));
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.toString();
      setTempUserState((prev) => ({
        ...prev,
        isLoading: false,
        isError: true,
        message,
      }));
    }
  };

  const removeTempUser = () => {
    window && localStorage.removeItem("tempuser");
    setTempUserState((prev) => ({ ...prev, tempUser: null }));
  };

  return (
    <TempAuthContext.Provider
      value={{ createTempUser, tempUserState, removeTempUser, tempUser }}
    >
      {children}
    </TempAuthContext.Provider>
  );
};

export default TempAuthContext;
