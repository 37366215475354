const getSubCategoriesList = ({ homeInitialData, code }) => {
  if (homeInitialData?.decorCategoriesList?.length > 0)
    return (
      homeInitialData.decorCategoriesList?.filter(
        (curr) => parseInt(curr.code) === parseInt(code)
      )[0]?.subCategories || []
    );

  return [];
};

export { getSubCategoriesList };
