import React, { useContext, useEffect, useState,memo } from "react";
import OcassionFilter from "../../productPreview/subComponents/OcassionFilter";
import DecorFilter from "../../productPreview/subComponents/DecorFilter";
import { useParams, useSearchParams } from "react-router-dom";
import ProductPreviewContext from "../../../context/ProductPreviewContext";


const FilterMenu = () => {
  const [filteroptions, setFilterOptions] = useState({
    ocassion: true,
    decor: false,
  });

  const { ocassion, decor } = filteroptions;

  const { categoryName } = useParams();

  const { filterService } = useContext(ProductPreviewContext);
  const [filterParams, setFilterParams] = useSearchParams();

  const [selectedOcassionFilter, setSelectedOcassionFilter] = useState(
    filterParams.get("ocassioncategory") || ""
  );
  const [selectedDecorFilter, setSelectedDecorFilter] = useState(
    filterParams.get("decorcategory") || ""
  );

  useEffect(() => {
    if (
      filterParams.get("ocassioncategory") ||
      filterParams.get("decorcategory")
    ) {
    }
    // eslint-disable-next-line
  }, []);

  const makeFilter = async () => {
    if (!selectedOcassionFilter && !selectedDecorFilter) return;

    if (selectedOcassionFilter && selectedDecorFilter) {
      setFilterParams({
        ocassioncategory: selectedOcassionFilter,
        decorcategory: selectedDecorFilter,
      });
    } else if (selectedOcassionFilter) {
      setFilterParams({
        ocassioncategory: selectedOcassionFilter,
      });
    } else if (selectedDecorFilter) {
      setFilterParams({
        decorcategory: selectedDecorFilter,
      });
    } else {
      setFilterParams({});
    }

    const res = await filterService(
      categoryName,
      {
        ocassioncategory: selectedOcassionFilter,
        decorcategory: selectedDecorFilter,
      }
    );

    console.log("res", res);
  };

  const spanstyle = "py-2 px-2";
  const spanAfterStyle =
    'bg-white relative after:absolute after:left-0 after:top-0 after:content-[" "] after:bg-[#2136D4] after:w-[2px] after:h-full';
  return (
    <>
      <div className="flex gap-3 md:gap-4">
        <div className="flex flex-col bg-[#D9D9D9]">
          <span
            onClick={() => setFilterOptions({ ocassion: true, decor: false })}
            className={`${ocassion && spanAfterStyle} ${spanstyle}`}
          >
            Ocassion Category
          </span>
          <span
            className={`${decor && spanAfterStyle} ${spanstyle}`}
            onClick={() => setFilterOptions({ ocassion: false, decor: true })}
          >
            Decor Category
          </span>
        </div>
        <div className="">
          {ocassion ? (
            <OcassionFilter
              selectedOcassionFilter={selectedOcassionFilter}
              setSelectedOcassionFilter={setSelectedOcassionFilter}
            />
          ) : (
            <DecorFilter
              selectedDecorFilter={selectedDecorFilter}
              setSelectedDecorFilter={setSelectedDecorFilter}
            />
          )}
        </div>
      </div>
      <div className="flex justify-end gap-4 py-2 px-3">
        <button className="border-[1px] rounded-sm md:rounded-md border-[#2136D4]  w-[30%] py-1 md:py-2">
          Clear filter
        </button>
        <button
          className={`bg-[#2136D4] w-[30%] py-1 md:py-2 rounded-sm md:rounded-md text-white`}
          onClick={makeFilter}
        >
          Apply
        </button>
      </div>
    </>
  );
};

export default memo(FilterMenu);
