import React, { useContext,memo } from "react";

import { BsFilterLeft } from "react-icons/bs";
import ProductPreviewContext from "../../context/ProductPreviewContext";
import Sort from "./subComponents/sort/Sort";

const SortAndFilterLg = () => {
  const { setShowFilter, showFilter } = useContext(ProductPreviewContext);

  return (
    <div className="flex items-center gap-8 lg:gap-12 font-semibold lg:text-lg lg:font-medium">
      <div
        className="flex items-center gap-1 cursor-pointer hover:opacity-70 transition-all"
        onClick={() => setShowFilter((prev) => !prev)}
      >
        <span className="transition-all lg:w-[6.3rem]">
          {showFilter ? "Hide" : "Show"} Filters
        </span>
        <BsFilterLeft size={21} />
      </div>
      <Sort />
    </div>
  );
};

export default memo(SortAndFilterLg);
