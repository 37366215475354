import React, { useContext, memo, useState } from "react";
import { BiCalendarEdit } from "react-icons/bi";

import SortAndFilterLg from "./SortAndFilterLg";
import moment from "moment";
import CalendarPop from "../../assets/calendar/CalendarPop";
import GLobalStoreContext from "../../context/GlobalStoreContext";

const PreviewHeaderLg = ({ productCount, text }) => {
  const { userStates } = useContext(GLobalStoreContext);
  // --------------- STATES ----------------
  const [isCalendar, setIsCalendar] = useState(false);

  return (
    <div className="hidden lg:flex justify-between pt-6 lg:pt-4 pb-2 lg:pb-4 px-12 lg:px-14 lg:sticky z-10 lg:top-[0rem] shadow bg-[#fff]">
      {/* calendar popup component */}

      {isCalendar && (
        <CalendarPop
          setIsCalendar={setIsCalendar}
          validStartingDate={moment()}
          validEndingDate={moment().add(30, "days")}
          minProductAvabilityDays={0}
        />
      )}
      <h2 className="flex items-center gap-1 font-semibold xl:text-lg xl:font-medium">
        Showing results for
        <span className="capitalize lg:font-semibold">“{text}”</span>
        <span>({productCount})</span>
      </h2>
      <div
        className="flex items-center gap-1 lg:text-lg"
        onClick={() => setIsCalendar(true)}
      >
        <BiCalendarEdit size={22} className="cursor-pointer" />
        <span>Celebrating on:</span>
        <span className="font-semibold">
          {moment(userStates.defaultCelebrationDate).format("ddd, Do MMM")}
        </span>
      </div>
      <SortAndFilterLg />
    </div>
  );
};

export default memo(PreviewHeaderLg);
