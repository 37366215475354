import { memo, useContext } from "react";
import ProductPreviewContext from "../../../../context/ProductPreviewContext";
import { getSortingObj } from "../../../../functions/searchAlgos/searchAlogHelperFunctions";

const Dropdown = ({ setSortParams, sortParams, closeModal }) => {
  const { resetPagePositions } = useContext(ProductPreviewContext);

  const handleClick = async (sort) => {
    if (sortParams.get("filter")) {
      const filter = JSON.parse(sortParams.get("filter"));
      filter["sort"] = getSortingObj(sort);
      setSortParams({ filter: JSON.stringify(filter) });
    } else {
      setSortParams({
        sort: sort,
      });
    }
    closeModal();
    await resetPagePositions();
  };
  const listitem =
    "my-1 font-normal hover:font-medium text-[1rem] cursor-pointer";

  return (
    <ul
      className={`bg-[#fff] menu w-44 px-4 rounded-b-lg py-3 shadow-md absolute right-0`}
    >
      <li className={listitem} onClick={() => handleClick("")}>
        Popularity
      </li>
      <li className={listitem} onClick={() => handleClick("latest")}>
        Latest
      </li>
      <li className={listitem} onClick={() => handleClick("discount")}>
        Discount
      </li>
      <li className={listitem} onClick={() => handleClick("high-to-low")}>
        Price: High to Low
      </li>
      <li className={listitem} onClick={() => handleClick("low-to-high")}>
        Price: Low to High
      </li>
    </ul>
  );
};

export default memo(Dropdown);
