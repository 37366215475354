import React, { useContext, useState } from "react";
import { BsChevronLeft, BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ResponsiveContext from "../context/ResponsiveContext";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { CiShoppingCart, CiHeart } from "react-icons/ci";
import { IconContext } from "react-icons/lib";
import CartContext from "../context/CartContext";
import CompRenderingContext from "../context/CompRenderingContext";
import ScrollHistoryContext from "../context/ScrollHistoryContext";
import SearchBar from "../components/navbar/search/SearchBar";
import { EBO_PHONE_NUMBER } from "../utils/constants";
import GLobalStoreContext from "../context/GlobalStoreContext";

export default function Header({
  link,
  title,
  productCount,
  subtitle,
  isLogo,
  helpPrev,
  productCode,
  refreshAllProductPage,
}) {
  const { tempCart } = useContext(CartContext);

  const { wishlistedArrayObj } = useContext(GLobalStoreContext);
  const { user } = useContext(AuthContext);
  const { screenWidth } = useContext(ResponsiveContext);
  const { setIsLoginModal } = useContext(CompRenderingContext);
  const { removeFromHistory } = useContext(ScrollHistoryContext);

  const navigate = useNavigate();

  const [isSearch, setIsSearch] = useState(false);

  const onWishListClick = () => {
    if (user) {
      navigate("/profile/wishlist");
    } else {
      setIsLoginModal(true);
    }
  };

  const onSearchClick = () => {
    setIsSearch(!isSearch);
  };

  if (isSearch) {
    return <SearchBar isSearch={isSearch} handleSearchClose={onSearchClick} />;
  }

  if (screenWidth <= 768) {
    return (
      <div className=" w-[100vw] pl-3 h-[3rem] items-center justify-between flex  bg-white z-50 fixed top-0 left-0 right-0 ">
        <div
          onClick={() => {
            const lastUrl = removeFromHistory();
            navigate(lastUrl.url);
            refreshAllProductPage && refreshAllProductPage();
          }}
          className="flex items-center cursor-pointer relative bottom-[1px]  gap-2  "
        >
          <BsChevronLeft className="text-[1.25rem] text-[black]" />
          {productCount ? (
            <div className="flex max-w-[14rem] flex-col ">
              <h1 className="text-[1.1rem] relative top-1 leading-[1.7rem] text-ellipsis  overflow-hidden   whitespace-nowrap font-bold text-[#363636] ">
                {title}
              </h1>
              {title !== "Cart" && title !== "Wishlist" && (
                <span className="relative  text-[0.75rem] text-gray-600">
                  {productCount} items
                </span>
              )}
            </div>
          ) : (
            !isLogo && (
              <span className="text-[1.1rem] max-w-[14rem] font-bold text-ellipsis  overflow-hidden   whitespace-nowrap  text-[#363636] ">
                {title}
              </span>
            )
          )}
          {isLogo && (
            <h1
              style={{
                fontFamily: "'Josefin Sans', sans-serif",
              }}
              className=" text-[2rem] text-[#2136d4] font-[800]  "
            >
              ebo
            </h1>
          )}
          {subtitle ? <p className="text-sm  mx-0.5">{subtitle}</p> : null}
        </div>
        <div className="flex items-center">
          {helpPrev && (
            <Link
              to={`https://wa.me/${EBO_PHONE_NUMBER}?text=Hi, I'm ${
                user ? user.name : "new at ebo"
              }, I'm trying to book a product whose product code is ${productCode}.`}
              className=" text-[#171717c4] hover:text-[#171717]   flex items-center justify-center gap-1 mr-4 text-[.9rem] "
            >
              <FaWhatsapp className="  text-[1.1rem]  text-[#4FCE5D] " />
              Need help ?
            </Link>
          )}

          {title !== "Cart" &&
            title !== "My Account" &&
            title !== "Wishlist" &&
            title !== "My Bookings" && (
              <div onClick={onSearchClick} className="  cursor-pointer">
                <BsSearch className=" mr-1 mt-1 text-[21px] " />
              </div>
            )}

          {title !== "Cart" && title !== "My Account" && (
            <div
              onClick={onWishListClick}
              style={{ textDecoration: "none" }}
              className="  cursor-pointer relative"
            >
              <IconContext.Provider
                value={{ className: "w-[1.75rem] h-[1.75rem] mr-1 mt-1" }}
              >
                <CiHeart />
              </IconContext.Provider>
              {wishlistedArrayObj?.length > 0 && (
                <div className=" w-3 h-3 flex items-center justify-center rounded-full blue-gradient absolute top-1 right-1 text-[10px]">
                  {wishlistedArrayObj?.length}
                </div>
              )}
            </div>
          )}

          {title !== "Cart" && title !== "My Account" && (
            <Link
              to={"/checkout"}
              className="cursor-pointer mr-2"
              style={{ textDecoration: "none" }}
            >
              <IconContext.Provider
                value={{ className: "w-[1.75rem] h-[1.75rem] mr-1 mt-1" }}
              >
                <CiShoppingCart />
              </IconContext.Provider>
              {tempCart && (
                <div className=" w-3 h-3 flex items-center justify-center rounded-full blue-gradient absolute top-2 right-[10px] text-[10px]">
                  1
                </div>
              )}
            </Link>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
