import React,  { createContext, useState } from "react";
const AddonContext = createContext();

export const AddonProvider = ({ children }) => {
  const [addonData, setAddonData] = useState({
    addons: [],
    addon: null,
    isLoading: false,
  });
  const [topUpAddonData, setTopUpAddonData] = useState({
    addons: [],
    addon: null,
    isLoading: false,
  });



  const [emptyFileInputState, setEmptyFileInputState] = useState(null);

  const getAddonsByCategory = async (category, isTopUpAddon) => {
    if (isTopUpAddon) {
      setTopUpAddonData((prev) => ({ ...prev, isLoading: true }));
    } else {
      setAddonData((prev) => ({ ...prev, isLoading: true }));
    }
    try {
      const {get_addon_by_query_obj} = await import('../api/addonApi')
      const res = await await get_addon_by_query_obj({
        queryObject: category === -1 ? {} : { category: category },
        skip: 0,
        limit: 100,
        sortingObj: { index: "asc" },
      });

      if (isTopUpAddon) {
        setTopUpAddonData((prev) => ({
          ...prev,
          isLoading: false,
          addons: res.addons,
        }));
      } else {
        setAddonData((prev) => ({
          ...prev,
          isLoading: false,
          addons: res.addons,
        }));
      }
    } catch (error) {
      console.log(error);

      if (isTopUpAddon) {
        setTopUpAddonData((prev) => ({ ...prev, isLoading: false }));
      } else {
        setAddonData((prev) => ({ ...prev, isLoading: false }));
      }
    }
  };

  // get addon by id
  const getAddonById = async (addonId) => {
    try {
      const {get_addon_by_query_obj} = await import('../api/addonApi')
      const res = await get_addon_by_query_obj({
        queryObject: { _id: addonId },
        skip: 0,
        limit: 1,
        sortingObj: {},
      });
      return res.addons[0];
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AddonContext.Provider
      value={{
        addonData,
        setAddonData,
        emptyFileInputState,
        setEmptyFileInputState,
        getAddonsByCategory,
        getAddonById,
        topUpAddonData,
        setTopUpAddonData,
      }}
    >
      {children}
    </AddonContext.Provider>
  );
};

export default AddonContext;
