import React from "react";

export default function HomeSkeleton() {
  const array = new Array(16).fill(0);

  return (
    <div className="flex flex-col justify-center items-center relative  md:top-[6rem]">
      <div
        style={{
          width: "calc(100vw - 1.8rem)",
        }}
        className=" w-full flex md:hidden justify-between h-[2rem]  mt-2 mb-2"
      >
        <div className="ebo_skeleton rounded-xl w-[3.5rem] h-full"></div>
        <div className="flex gap-3">
          <div className="ebo_skeleton rounded-full h-full w-[2rem]"></div>
          <div className="ebo_skeleton rounded-full h-full w-[2rem]"></div>
        </div>
      </div>
      <div
        style={{
          width: "calc(100vw - 1.8rem)",
        }}
        className="mt-1 mb-4 md:hidden  w-full "
      >
        <div className="  ebo_skeleton w-[15rem] h-[1rem] rounded-md mb-1"></div>
        <div className="  ebo_skeleton w-[8rem] h-[1rem] rounded-md"></div>
      </div>
      <div
        style={{
          width: "calc(100vw - 1.8rem)",
        }}
        className="flex w-full ebo_skeleton md:hidden rounded-xl justify-between h-[2.5rem] mb-4"
      ></div>

      <div className="w-full flex flex-col items-center px-1 justify-center">
        <div
          style={{
            resize: "stretch",
            width: "calc(100vw - 1.8rem)",
          }}
          className="ebo_skeleton rounded-xl aspect-[2/1] md:aspect-[4/1]"
        ></div>

        <div
          style={{}}
          className=" rounded-full  h-[.5rem] w-[10rem] mt-[10px] "
        />
      </div>
      {/* top categories list skeleton */}
      <p className={`w-full flex items-center justify-center`}>
        <span
          className={`ebo_skeleton  text-center font-[600] h-[16px] w-[15rem] rounded-full mb-[20px]`}
        ></span>
      </p>
      <div
        style={{
          paddingInline: "4vw",
          rowGap: "4vw",
        }}
        className={`flex flex-row flex-wrap justify-between `}
      >
        {array?.map((curr, idx) => {
          return (
            <div
              key={idx}
              style={{
                borderWidth: 1,
                borderColor: "#f0f0f0",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  resize: "stretch",
                }}
                className="ebo_skeleton  w-[21vw] md:w-[10vw] h-[25.2vw] md:h-[12vw]"
              ></div>
            </div>
          );
        })}
      </div>
      {/* home offer banner */}
      <div className="w-full flex flex-col items-center mt-[8px] justify-center">
        <div
          style={{
            resize: "stretch",
            width: "95%",
            height: "7.5rem",
          }}
          className="ebo_skeleton rounded-xl"
        ></div>
      </div>
      <CommonSkeleton />
      <CommonSkeleton />
    </div>
  );
}

const HomeBannerSkeleton = () => {
  return (
    <div className="w-full flex flex-col items-center mt-[8px] justify-center">
      <div
        style={{
          resize: "stretch",
          width: "95%",
          height: "7.5rem",
        }}
        className="ebo_skeleton rounded-xl"
      ></div>
    </div>
  );
};
const CommonSkeleton = () => {
  return (
    <div className="w-full flex flex-col items-center mt-[8px] justify-center">
      <p className={`w-[95%] flex items-center justify-start`}>
        <span
          className={`ebo_skeleton text-center font-[600] h-[16px] w-[15rem] rounded-full mb-[10px]`}
        ></span>
      </p>
      <div className="ebo_skeleton  w-[95%] h-[8rem] md:h-[10rem]  rounded-xl"></div>
    </div>
  );
};

const CarouselSkeleton = () => {
  return (
    <div className="w-full flex flex-col items-center px-1 justify-center">
      <div
        style={{
          resize: "stretch",
          width: "calc(100vw - 1.8rem)",
        }}
        className="ebo_skeleton rounded-xl aspect-[2/1] md:aspect-[4/1] "
      ></div>

      <div className=" rounded-full  h-[.5rem] w-[10rem] mt-[10px] " />
    </div>
  );
};

const HomeCategoriesSkeleton = () => {
  const array = new Array(16).fill(0);

  return (
    <>
      <p className={`w-full flex items-center justify-center`}>
        <span
          className={`ebo_skeleton  text-center font-[600] h-[16px] w-[15rem] rounded-full mb-[20px]`}
        ></span>
      </p>
      <div
        style={{
          paddingInline: "4vw",
          rowGap: "4vw",
        }}
        className={`flex flex-row flex-wrap justify-between  `}
      >
        {array?.map((curr, idx) => {
          return (
            <div
              key={idx}
              style={{
                borderWidth: 1,
                borderColor: "#f0f0f0",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  resize: "stretch",
                }}
                className="ebo_skeleton w-[21vw] md:w-[10vw] h-[25.2vw] md:h-[12vw] "
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export {
  CarouselSkeleton,
  HomeCategoriesSkeleton,
  CommonSkeleton,
  HomeBannerSkeleton,
};
