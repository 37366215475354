import React from "react";
import { isBrowser, isMobile } from "react-device-detect";

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    const errorReloadCount = sessionStorage.getItem("_ERROR_COUNT");
    if (!errorReloadCount || parseInt(errorReloadCount) <= 0) {
      sessionStorage.setItem(
        "_ERROR_COUNT",
        errorReloadCount ? parseInt(errorReloadCount) + 1 : 1
      );
      window.location.reload();
    } else {
      sessionStorage.setItem(
        "_ERROR_COUNT",
        errorReloadCount ? parseInt(errorReloadCount) + 1 : 1
      );
    }

    const errorInfos = {
      message: error.message,
      type: "_ERROR_BOUNDARY_CATCH",
      errorFromSiteName: "ebo",
      errorFromUrl: window.location.href,
      userAgent: window.navigator.userAgent,
      userAgentData: window.navigator.userAgentData,
      geolocation: window.navigator.geolocation,
      connection: window.navigator.connection,
      device: isMobile ? "mobile" : isBrowser ? "browser" : "notDefined",
    };

    // Import the newErrorCreationApi dynamically only when needed
    const { newErrorCreationApi } = await import("../api/errorApi");
    await newErrorCreationApi(errorInfos);

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
