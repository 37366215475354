import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGlobalStore } from "../../context/GlobalStoreContext";
import { v2Api } from "../../api/v2.api";
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation";
import { useHomePageContext } from "../../context/HomepageContext";

const VIDEO_ASPECT_RATIO = "9/16";

export default function HIWvideoPreview1({
  isPadding = true,
  handleBack = () => {},
}) {
  const { storeStates, setStoreStates } = useGlobalStore();
  const { homeInitialData } = useHomePageContext();
  const [videoStates, setVideoStates] = useState({
    videoLoaded: true,
  });

  useEffect(() => {
    if (
      homeInitialData.initialData !== null &&
      storeStates.HIWvideos === null
    ) {
      fetchVideoDetails();
    }

    // eslint-disable-next-line
  }, [homeInitialData.initialData]);
  const handleToggleExpandedView = () => {
    setStoreStates((prev) => ({
      ...prev,
      HIWExpandedView: true,
    }));
  };

  const EACH_VIDEO_WIDTH =
    window.innerWidth > 768 ? window.innerWidth / 5 + 7 : 140; // IN PX

  const fetchVideoDetails = useCallback(async () => {
    try {
      const res = await v2Api({
        operation: "getHowItWorksData",
        tags: ["home"],
      });

      if (res.isSuccess) {
        setStoreStates((prev) => ({ ...prev, HIWvideos: res.data }));
      }
    } catch (error) {
      console.error("Error fetching video details", error);
    }
  }, [setStoreStates]);

  if (storeStates.HIWvideos === null) {
    return (
      <div className="w-full px-4 ">
        <div className="w-full h-[8rem] flex-shrink-0 ebo_skeleton rounded-lg "></div>
      </div>
    );
  }

  const handleVideoClick = async () => {
    const today = new Date().toDateString();
    const clickedDate = localStorage.getItem(`HIW_d`);

    if (clickedDate === today) {
      return; // User has already clicked today
    }

    localStorage.setItem(`HIW_d`, today);

    try {
      await v2Api({
        operation: "handleVideoClicks",
        tags: ["home"],
        props: { tags: ["how it works (reels)"] },
      });
    } catch (error) {
      console.error("Error updating click count", error);
    }
  };

  if (storeStates.HIWvideos?.length === 0) return;

  return (
    <div className={`w-full lg:mb-2 ${isPadding ? "mt-2  px-4" : ""}`}>
      <div className="w-full  rounded-lg bg-gradient-to-b from-blue-100 to-white px-4 pt-3 flex-col">
        <div
          onClick={() => {
            handleBack();
          }}
          className="w-full text-center mb-3 text-lg font-semibold"
        >
          Celebrate, the{" "}
          <span
            style={{ fontFamily: "Josefin Sans, sans-serif" }}
            className="text-[#2136d4] text-xl font-extrabold"
          >
            ebo
          </span>{" "}
          way 🥳
        </div>
        <div className="w-full flex gap-4 flex-nowrap">
          <Swiper
            spaceBetween={0}
            slidesPerView="auto"
            freeMode={true}
            grabCursor={true}
          >
            {storeStates.HIWvideos?.map((video, index) => {
              return (
                <SwiperSlide key={index} className="!w-auto">
                  <div
                    onClick={() => {
                      handleToggleExpandedView();
                      handleVideoClick();
                    }}
                    key={index}
                    className="flex-col items-start flex-shrink-0 "
                    style={{
                      width: `${EACH_VIDEO_WIDTH}px`,
                    }}
                  >
                    <div className="flex w-full items-center relative gap-2 mb-2 pl-0.5 whitespace-nowrap">
                      <div className="flex-col -space-y-0.5">
                        <div className=" text-sm lg:text-base font-medium">
                          {video?.options?.title}
                        </div>
                        <div className="text-xs lg:text-sm text-gray-600 ">
                          {video?.options?.subTitle}
                        </div>
                      </div>
                      {index !== storeStates.HIWvideos?.length - 1 && (
                        <div className="w-full min-h-0.5 rounded-full  bg-gradient-to-r from-[#2136d4] via-[#2136d4] to-[#ffffff00]"></div>
                      )}
                    </div>
                    <div
                      className="relative w-[90%] lg:w-[60%] bg-gray-300  rounded-md overflow-hidden"
                      style={{
                        aspectRatio: VIDEO_ASPECT_RATIO,
                      }}
                    >
                      {!videoStates.videoLoaded &&
                        !video?.tags?.includes("imageUrl") && (
                          <div className="bg-black absolute inset-0 z-[3] rounded-lg flex-center">
                            <div
                              className="loader w-[1.25rem]"
                              style={{
                                borderTopColor: "white",
                              }}
                            ></div>
                          </div>
                        )}

                      <video
                        src={video?.collapsedVideoUrl}
                        loop
                        muted
                        autoPlay
                        playsInline={true}
                        controls={false}
                        controlsList="nodownload nofullscreen noremoteplayback"
                        className="absolute inset-0 w-full h-full object-cover"
                        onLoadStart={() => {
                          setVideoStates((prev) => ({
                            ...prev,
                            videoLoaded: false,
                          }));
                        }}
                        onLoadedData={() => {
                          setVideoStates((prev) => ({
                            ...prev,
                            videoLoaded: true,
                          }));
                        }}
                      ></video>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
