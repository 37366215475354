import axios from "axios";

const BaseUrl = process.env.REACT_APP_BACKEND_URL;

const get_banners = async ({ queryObject, skip, limit, sortingObj }) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/homepage/get_banners`, {
      queryObject,
      skip: skip || 0,
      limit,
      sortingObj: sortingObj || { placementPosition: "desc" },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

export { get_banners };
