import React, { createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollHistoryContext = createContext();

export const ScrollHistoryProvider = ({ children }) => {
  const location = useLocation();
  const addToHistory = (url, userPagePosition) => {
    const pathHistory = JSON.parse(sessionStorage.getItem("S_PATH")) || [];
    if (
      pathHistory.length !== 0 &&
      pathHistory[pathHistory.length - 1].url === url
    ) {
      return;
    }
    pathHistory.push({ url, userPagePosition });
    sessionStorage.setItem("S_PATH", JSON.stringify(pathHistory));
  };

  const removeFromHistory = () => {
    const pathHistory = JSON.parse(sessionStorage.getItem("S_PATH"));
    const lastUrl = pathHistory[pathHistory.length - 2] || {
      url: "/",
      userPagePosition: 0,
    };
    pathHistory.pop();
    sessionStorage.setItem("S_PATH", JSON.stringify(pathHistory));
    return lastUrl;
  };

  useEffect(() => {
    addToHistory(location.pathname + location.search, 0);

    // eslint-disable-next-line
  }, [location]);

  return (
    <ScrollHistoryContext.Provider value={{ addToHistory, removeFromHistory }}>
      {children}
    </ScrollHistoryContext.Provider>
  );
};

export default ScrollHistoryContext;
