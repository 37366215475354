import React from "react";
import { Link } from "react-router-dom";

export default function GetIfFooter() {
  return (
    <div className="bg-[#f0f5fe] px-4 pt-2">
      <p className="font-medium">Download the app</p>
      <div className="flex items-center  -m-1">
        <Link to="https://ebo.onelink.me/HcT2/tws2pnj0">
          <img
            src="https://img.ebonow.com/Posters/Playstore.webp"
            alt=""
            className="w-[150px] h-[60px] object-cover "
          />
        </Link>
        <Link to="https://ebo.onelink.me/HcT2/tws2pnj0">
          <img
            src="https://img.ebonow.com/Posters/Appstore.webp"
            alt=""
            className="w-[150px] h-[60px] object-cover "
          />
        </Link>
      </div>
    </div>
  );
}
