import React from "react";
import CurrentLocation from "./CurrentLocation";
import ServiceableCities from "./ServiceableCities";
import PredictionsList from "./PredictionsList";
import SavedAddresses from "./SavedAddresses";

export default function DefaultLocationSuggestions({
  states,
  locationData,
  setStates,
}) {
  if (states.defaultUi) {
    return (
      <div className="flex flex-col pb-8 bg-white h-full overflow-scroll w-full ">
        <div className="w-full min-h-[5rem] "></div>
        <div className="w-full min-h-[8.5rem] bg-[white] absolute top-0 z-[1] "></div>
        <div className="px-4 mt-2">
          <CurrentLocation states={states} setStates={setStates} />
        </div>
        <div className="px-4 pt-2">
          <PredictionsList states={states} setStates={setStates} />
        </div>

        <ServiceableCities states={states} setStates={setStates} />
        <div className="px-4 pt-6">
          <SavedAddresses
            setStates={setStates}
            locationData={locationData}
            states={states}
          />
        </div>
      </div>
    );
  }
}
