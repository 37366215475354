import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReceiverDetailForm from "./ReceiverDetailForm";
import AuthContext from "../../../../context/AuthContext";
import SaveAddressAs from "./SaveAddressAs";
import MoreAddressInfo from "./MoreAddressInfo";
import {
  add_address_v2_api,
  update_address_bq,
} from "../../../../api/addressApi";
import AddressContext from "../../../../context/AddressContext";
import CompRenderingContext from "../../../../context/CompRenderingContext";
import { FaLocationArrow } from "react-icons/fa6";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import MapEmbed from "../../../../assets/maps/MapEmbed";

/*
 steps to use this component
 step 1: create state

   const [modalsRenderInfo, setModalsRenderInfo] = useState({
    addAndModiAddress: {
      isRender: false,
      options: {
        customZIndex: 15,
        isEdit: false,
        editingAddressInfo: null
      },
    },
  });

 step 2:: import the component conditionally

    <AnimatePresence>
        {locationData.addAndModiAddress.isRender && (
          <AddAndModiAddress
            modalsRenderInfo={modalsRenderInfo}
            setModalsRenderInfo={setModalsRenderInfo}
          />
        )}
      </AnimatePresence>

  step 3: change the state conditionally to render the modal
*/

export default function AddAndModiAddress() {
  const { user } = useContext(AuthContext);
  const { locationData, setLocationData } = useContext(CompRenderingContext);
  const { getAddress } = useContext(AddressContext);
  const [formData, setFormData] = useState({
    receiversDetails: {
      name: user.name,
      mobileNumber: parseInt(user.phoneNumber),
      type: "mySelf",
    },
    locationv2: {
      lat: locationData?.selectedLocation?.lat || "",
      lng: locationData?.selectedLocation?.lng || "",
      addressLine1: locationData?.selectedLocation?.addressLine1 || "",
      city: locationData?.selectedLocation?.city || "",
      state: locationData?.selectedLocation?.state || "",
      pincode: locationData?.selectedLocation?.pincode || "",
      addressLine2: locationData?.selectedLocation?.addressLine2 || "",
    },
    addressLabel: "home",
    address1: "",
    address2: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    locationData.addAndModiAddress?.options?.isEdit &&
      editInfoDistribution(
        locationData.addAndModiAddress?.options?.editingAddressInfo
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormData((p) => ({
      ...p,
      locationv2: {
        lat: locationData?.selectedLocation?.lat || "",
        lng: locationData?.selectedLocation?.lng || "",
        addressLine1: locationData?.selectedLocation?.addressLine1 || "",
        city: locationData?.selectedLocation?.city || "",
        state: locationData?.selectedLocation?.state || "",
        pincode: locationData?.selectedLocation?.pincode || "",
        addressLine2: locationData?.selectedLocation?.addressLine2 || "",
      },
    }));

    // eslint-disable-next-line
  }, [locationData?.selectedLocation?.lat]);

  const handleClose = () => {
    setLocationData((p) => ({
      ...p,
      addAndModiAddress: {
        isRender: false,
        options: {},
      },
      isAddressSaveModal: false,
      isLocationAddModalRender: false,
    }));
  };

  const handleBackToLocation = () => {
    setLocationData((p) => ({
      ...p,
      addAndModiAddress: {
        isRender: false,
        options: {},
      },
      isAddressSaveModal: true,
      isLocationAddModalRender: true,
    }));
  };

  const msgPop = (msg) => {
    setLocationData((p) => ({
      ...p,
      toast1: {
        isRender: true,
        text: msg,
        options: {
          customZIndex: 999,
        },
      },
    }));
  };

  const editInfoDistribution = (address) => {
    setFormData({
      ...address,
      locationv2: locationData.selectedLocation,
    });
  };

  const handleEdtSubmit = async () => {
    try {
      const savingAddressData = {
        ...formData,
      };
      const res = await update_address_bq({
        queryObj: {
          _id: locationData.addAndModiAddress?.options?.editingAddressInfo._id,
        },
        updateObj: {
          ...savingAddressData,
        },
      });
      if (res.isSuccess) {
        await getAddress();
        handleClose();
        msgPop("Address Edited");
      } else {
        msgPop("Error Occured! Try letter");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (!formData?.locationv2?.lat) {
        msgPop("Please set the location");
        setIsLoading(false);
        return;
      }
      if (!formData.address1) {
        msgPop("Please fill the address");
        setIsLoading(false);
        return;
      }
      if (
        !formData.receiversDetails.name ||
        !formData.receiversDetails.mobileNumber
      ) {
        msgPop("Please fill the receiver's details");
        setIsLoading(false);
        return;
      }

      if (locationData.addAndModiAddress?.options?.isEdit) {
        await handleEdtSubmit();
        setIsLoading(false);
        return;
      }

      const savingAddressData = {
        ...formData,
      };
      const res = await add_address_v2_api(savingAddressData);
      if (res.isSuccess) {
        await getAddress();
        handleClose();
        msgPop("Address Saved");
      } else {
        msgPop("Error Occured! Try letter");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 z-[999]">
      <div
        onClick={handleBackToLocation}
        className="fixed right-0 top-0 left-0 bottom-0 z-[-1] bg-[#00000026]"
      ></div>
      <motion.div
        initial={{ translateX: -500, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: -500, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="w-full text-[.875rem] flex flex-col items-center  max-w-[30rem] fixed top-0 left-0 bottom-0 z-[2] "
      >
        <div
          onClick={handleBackToLocation}
          className="text-[white] text-[1.75rem] fixed top-4 left-4 z-[20]"
        >
          <BsArrowLeftCircleFill />
        </div>
        <div className="flex h-[20%]  w-full ebo_skeleton"></div>
        <div className="flex h-[30%] fixed top-0 left-0 w-full max-w-[30rem]  z-[2]  bg-[#0000006f]"></div>
        <div className="bg-[gray] ebo_skeleton fixed top-[3rem] flex items-center justify-center  pb-[8rem] left-0 w-full max-w-[30rem]  h-[30%]">
          <MapEmbed
            lat={locationData?.selectedLocation?.lat}
            lng={locationData?.selectedLocation?.lng}
            height="500px"
            width="600px"
          />
        </div>
        <div className="flex relative flex-col w-full bg-[white] z-20 rounded-xl border h-[85%]">
          <div className="flex relative  px-4 pb-2 pt-3 flex-col">
            <div className="font-[500] flex items-center gap-2 pb-3 capitalize text-[1.125rem] ">
              <FaLocationArrow className="text-[#2136d4] text-[1.25rem]" />
              {locationData?.selectedLocation?.addressLine1}
            </div>
            <div className="text-[400] mb-1 text-[gray] font-[400] flex items-center gap-1 first-letter:capitalize text-[.8125rem] ">
              {locationData?.selectedLocation?.addressLine2}
            </div>
          </div>
          <div className="flex  flex-col w-full  px-4 pb-[6rem] overflow-scroll h-full">
            <ReceiverDetailForm
              user={user}
              formData={formData}
              setFormData={setFormData}
            />
            <SaveAddressAs formData={formData} setFormData={setFormData} />
            {/* <LocationSection formData={formData} setFormData={setFormData} /> */}
            <MoreAddressInfo formData={formData} setFormData={setFormData} />
          </div>
          {/* save address button */}
          <div className=" fixed bottom-0 left-0 w-full max-w-[30rem] md:border-r border-gray-200     px-4 bg-white py-3 ">
            <button
              onClick={() => {
                !isLoading && handleSubmit();
              }}
              className=" w-full font-[500] uppercase text-center  rounded-md blue-gradient bg-[#2136d4] py-3"
            >
              {isLoading ? (
                <div
                  style={{ borderTop: "3px solid white" }}
                  className=" loader w-[1rem] text-[white]  "
                ></div>
              ) : (
                "save address"
              )}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
