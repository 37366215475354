import React, { useContext, memo } from "react";
import HomePageContext from "../../context/HomepageContext";
import StructuredAddressContainer from "../myAddress/subComponents/StructuredAddressContainer";

function NavbarAddress() {
  const { homeInitialData } = useContext(HomePageContext);

  const NavBarAddressSkeleton = () => {
    return (
      <div className="mt-2 px-4">
        <div className="  ebo_skeleton w-[15rem] h-[1.2rem] rounded-md mb-2"></div>
        <div className="  ebo_skeleton w-[8rem] h-[1.2rem] rounded-md"></div>
      </div>
    );
  };

  if (homeInitialData.isFetching) return <NavBarAddressSkeleton />;
  else return <StructuredAddressContainer type="navbarAdd" />;
}

export default memo(NavbarAddress);
