import React,{memo} from "react";
import { RxCross2 } from "react-icons/rx";
import FilterMenu from "./modalComponents/FilterMenu";

const FilterModal = () => {
  return (
    <div>
      <input type="checkbox" id="filter-modal" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle xl:hidden">
        <div className="modal-box p-0 text-[#323232]">
          <div className="flex justify-between items-center px-3 pt-3 pb-2 border-b-[1px]">
            <span className="font-medium text-[1.1rem]">Filters</span>
            <label htmlFor="filter-modal">
              <RxCross2 size={21} />
            </label>
          </div>

          <FilterMenu />
        </div>
      </div>
    </div>
  );
};

export default memo(FilterModal);
