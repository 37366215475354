import React, { useContext, useEffect, useState,memo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ProductPreviewContext from "../../../context/ProductPreviewContext";
import DecorFilter from "./DecorFilter";
import OcassionFilter from "./OcassionFilter";

const Filter = () => {
  const { categoryName } = useParams();
  const { showFilter, setShowFilter,filterService } = useContext(
    ProductPreviewContext
  );
  const [filterParams, setFilterParams] = useSearchParams();

  const [selectedOcassionFilter, setSelectedOcassionFilter] = useState(
    filterParams.get("ocassioncategory") || ""
  );
  const [selectedDecorFilter, setSelectedDecorFilter] = useState(
    filterParams.get("decorcategory") || ""
  );

  useEffect(() => {
    if (
      filterParams.get("ocassioncategory") ||
      filterParams.get("decorcategory")
    ) {
      setShowFilter(true);
    }
    // eslint-disable-next-line
  }, []);

  const makeFilter = async () => {
    if (!selectedOcassionFilter && !selectedDecorFilter) return;
    if (selectedOcassionFilter && selectedDecorFilter) {
      setFilterParams({
        ocassioncategory: selectedOcassionFilter,
        decorcategory: selectedDecorFilter,
      });
    } else if (selectedOcassionFilter) {
      setFilterParams({
        ocassioncategory: selectedOcassionFilter,
      });
    } else if (selectedDecorFilter) {
      setFilterParams({
        decorcategory: selectedDecorFilter,
      });
    } else {
      setFilterParams({});
    }

    const res = await filterService(
      categoryName,
      {
        ocassioncategory: selectedOcassionFilter,
        decorcategory: selectedDecorFilter,
      }
    );

    console.log("res", res);
  };

  const active = "lg:w-[22%]";
  const nonActive = "lg:w-[0%] lg:-translate-x-[11rem]";

  return (
    <div
      className={`lg:py-[0.9rem] lg:pl-4 hidden lg:block filter-transition ${
        showFilter ? active : nonActive
      }`}
    >
      <div className="w-full flex flex-col border-[1px] rounded-md pl-10 sticky xl:top-[10.7rem] bg-[#fff]">
        <OcassionFilter
          selectedOcassionFilter={selectedOcassionFilter}
          setSelectedOcassionFilter={setSelectedOcassionFilter}
        />
        <DecorFilter
          selectedDecorFilter={selectedDecorFilter}
          setSelectedDecorFilter={setSelectedDecorFilter}
        />
        <button
          className={`bg-[#2136D4] w-[83%] xl:py-2 xl:rounded-md mb-3 text-white`}
          onClick={makeFilter}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default memo(Filter)
