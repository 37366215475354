import React, { memo } from "react";
import Dropdown from "./Dropdown";
import { BiSortAlt2 } from "react-icons/bi";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const Sort = () => {
  const [showSort, setShowSort] = useState(false);
  const [sortParams, setSortParams] = useSearchParams();

  return (
    <div className="relative">
      <label
        className="cursor-pointer hover:opacity-80 flex items-center gap-1"
        onClick={() => setShowSort((prev) => !prev)}
      >
        <span>
          Sort by
          {sortParams.get("sort") && (
            <span className="capitalize font-normal text-[1rem] mx-1">
              : ({sortParams.get("sort")})
            </span>
          )}
        </span>{" "}
        <BiSortAlt2 className={`transition-all ${showSort && "rotate-90"}`} />
      </label>
      {showSort && (
        <Dropdown
          sortParams={sortParams}
          closeModal={() => {
            setShowSort(false);
          }}
          setSortParams={setSortParams}
        />
      )}
    </div>
  );
};

export default memo(Sort);
