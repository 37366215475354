import React, { useContext, useState } from "react";
import { categories, cities } from "./footerData";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { motion } from "framer-motion";
import ResponsiveContext from "../../context/ResponsiveContext";

export default function ServicLocations() {
  const { screenWidth } = useContext(ResponsiveContext);

  const [states, setStates] = useState({
    ...cities.reduce((acc, city) => {
      acc[city.city] = screenWidth > 768 ? true : false;
      return acc;
    }, {}),
  });

  return (
    <section className=" flex flex-col bg-[#f0f5fe] -mb-3 md:mb-0  w-full px-[2.5%]">
      {cities.map((city, idx) => {
        return (
          <div
            key={idx}
            className="flex md:my-[1rem]  my-1 flex-col items-start "
          >
            <div className=" flex items-center justify-between pl-1 w-full">
              <Link
                to={"/kw/decoration-service-in-" + city.city}
                className=" capitalize text-[1rem] mb-2 font-[500] "
              >
                {city.city}
              </Link>

              <FaAngleDown
                style={{
                  rotate: states[city?.city] ? "180deg" : "0deg",
                }}
                onClick={() => {
                  setStates((p) => ({ ...p, [city?.city]: !p[city?.city] }));
                }}
                className="text-[1.25rem] cursor-pointer transition-all "
              />
            </div>
            <motion.div
              animate={{ height: states[city?.city] ? "auto" : 0 }}
              style={{ rowGap: ".5rem" }}
              className="w-full gap-2 grid grid-cols-1 sm:grid-cols-2 overflow-hidden md:grid-cols-3 lg:grid-cols-4"
            >
              {city.locations.map((location) => {
                return (
                  <Link
                    key={location.location}
                    to={`/kw/balloon-decoration-service-in-${location.location}`}
                    className="  rounded-lg border text-[.8125rem] border-gray-200 bg-white  text-center whitespace-nowrap py-2 font-[400]"
                  >
                    Balloon decoration service in{" "}
                    <span className="capitalize">
                      {location.location.split("-").join(" ")}
                    </span>
                  </Link>
                );
              })}
              {categories.map((category) => {
                return (
                  <Link
                    key={city.city + category}
                    to={`/kw/${category}-decoration-service-in-${city.city}`}
                    className="  rounded-lg border text-[.8125rem] border-gray-200 bg-white  text-center whitespace-nowrap py-2 font-[400]"
                  >
                    <span className="capitalize">{category} </span>
                    decoration service in{" "}
                    <span className="capitalize">{city.city}</span>
                  </Link>
                );
              })}
            </motion.div>
          </div>
        );
      })}
    </section>
  );
}
