import React, { useRef, memo } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSearchParams } from "react-router-dom";

import SortList from "./modalComponents/SortList";
const SortModal = () => {
  const [sortParams, setSortParams] = useSearchParams();
  const sortModalRef = useRef();
  const closeModal = () => {
    if (sortModalRef.current) {
      // Setting the checked property of the checkbox to false to close the modal
      sortModalRef.current.checked = false;
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id="sort-modal"
        className="modal-toggle"
        ref={sortModalRef}
      />
      <div className="modal modal-bottom sm:modal-middle xl:hidden">
        <div className="modal-box p-0 text-[#323232]">
          <div className="flex justify-between items-center px-3 pt-3 pb-2 border-b-[1px]">
            <span className="font-medium text-[1.1rem]">Sort by</span>
            <label htmlFor="sort-modal">
              <RxCross2 size={21} />
            </label>
          </div>
          <SortList
            sortParams={sortParams}
            closeModal={closeModal}
            setSortParams={setSortParams}
          />
        </div>
      </div>
    </>
  );
};

export default memo(SortModal);
