import {
  calculate_addons_price,
  calculate_offer_discount,
} from "../../../functions/tempCartFunc";

export const serviceCheckForCart = async (hubId, cart) => {
  if (!hubId) return { success: false, hubMissing: true };
  const productPriceDetail = cart?.product?.listingPriceHubWise?.find(
    (currHub) => currHub.hub === hubId
  );

  if (!productPriceDetail) return { success: true, service: false };

  const addonsPrice = calculate_addons_price(cart.addOns, hubId);

  const offerDiscount = calculate_offer_discount(
    cart.offers,
    productPriceDetail?.sellingPrice
  );
  if (productPriceDetail?.costPrice + addonsPrice === cart.cartPrice)
    return { success: true, service: true, cart: cart, cartChange: false };

  // creating new cart
  const newCart = {
    ...cart,
    buyingPrice: productPriceDetail?.sellingPrice + addonsPrice - offerDiscount,
    cartPrice: productPriceDetail?.costPrice + addonsPrice,
  };

  return { success: true, service: true, cart: newCart, cartChange: true };
};
