import React from "react";

const Spinner = ({ color, top, left, size }) => {
  return (
    <div
      className={`absolute ${top ? top : "top-[49%]"} ${
        left ? left : "left-[48%]"
      } rounded-md z-20`}
    >
      <div className="loader w-[2rem] "></div>
    </div>
  );
};

export default Spinner;
