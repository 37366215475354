import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot, hydrateRoot } from "react-dom/client";

const container = document.getElementById("root");

const initialDataFromServer = window.__INITIAL_DATA__FROM__SERVER__; // Access the initial data
// while production mode

// hydrateRoot(
//   container,
//   <Router>
//     <App
//       initialDataFromServer={
//         initialDataFromServer
//           ? typeof initialDataFromServer === "string"
//             ? JSON.parse(initialDataFromServer)
//             : initialDataFromServer
//           : null
//       }
//     />
//   </Router>
// );

// while development mode

const root = createRoot(container);

root.render(
  <Router>
    <App initialDataFromServer={initialDataFromServer} />
  </Router>
);

reportWebVitals();
