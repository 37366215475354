import React, { useContext, memo, useState } from "react";
import { BsFilterLeft, BsChevronDown } from "react-icons/bs";
import { MdOutlineEditCalendar } from "react-icons/md";
import moment from "moment";
import CalendarPop from "../../assets/calendar/CalendarPop";
import GLobalStoreContext from "../../context/GlobalStoreContext";

const SortAndFilter = () => {
  const { userStates } = useContext(GLobalStoreContext);

  // --------------- STATES ----------------
  const [isCalendar, setIsCalendar] = useState(false);

  const liststyle =
    "flex items-center gap-[3px] px-2 border-2 rounded-full p-[0.3rem]";

  return (
    <ul className="flex justify-between items-center px-3 md:px-6 py-2 border-b-[1px] text-sm  bg-white">
      {/* calendar popup component */}

      {isCalendar && (
        <CalendarPop
          setIsCalendar={setIsCalendar}
          validStartingDate={moment()}
          validEndingDate={moment().add(30, "days")}
          minProductAvabilityDays={0}
        />
      )}
      <label htmlFor="filter-modal" className={`${liststyle}`}>
        <span className="font-medium md:font-semibold text-[0.9rem] md:text-[1rem] ">
          Filter
        </span>
        <BsFilterLeft />
      </label>
      <li
        className={`${liststyle} text-sm md:text-sm p-[0.5rem] border-2 rounded-full`}
        onClick={() => setIsCalendar(true)}
      >
        <MdOutlineEditCalendar
          className="ml-1"
          size={17}
          onClick={() => setIsCalendar(true)}
        />
        <div className="flex">
          <span className="font-semibold">
            {moment(userStates.defaultCelebrationDate).format("ddd, Do MMM")}
          </span>
        </div>
      </li>
      <label htmlFor="sort-modal" className={`${liststyle}`}>
        <span className="font-medium md:font-semibold text-[0.9rem] md:text-[1rem] ">
          Sort by
        </span>
        <BsChevronDown className=" md:text-lg" />
      </label>
    </ul>
  );
};

export default memo(SortAndFilter);
