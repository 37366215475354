import React from "react";
import FooterRight from "../../../footer/FooterRight";
import FooterBottom from "../../../footer/FooterBottom";
import FootMoreAboutUs from "../../../footer/FootMoreAboutUs";
import GetItFooter from "../../../../assets/mobileAppAssets/GetItFooter";
import KwpFooterLeft from "./KwpFooterLeft";
import ServicLocations from "../../../footer/ServicLocations";

export default function KwpFooter() {
  return (
    <footer className="w-full bg-[#f0f5fe] mt-8 pt-[2rem] flex flex-col">
      <ServicLocations />
      <div className="ebo-footer w-full">
        <div className="-my-6 md:hidden -mt-4">
          <GetItFooter />
        </div>
        <KwpFooterLeft />
        <FooterRight />
      </div>
      <div className="  h-[6rem] pl-4 bg-[#f0f5fe] hidden md:block">
        <GetItFooter />
      </div>
      <FooterBottom />
      <FootMoreAboutUs />
    </footer>
  );
}
