import axios from "axios";

export const v2Api = async (options) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/`,
      {
        options: { ...options, token: token },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error logging user location:", error);
  }
};
