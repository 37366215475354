import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

function FootMoreAboutUs() {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked((prevState) => !prevState);
  };
  return (
    <div
      onClick={handleClick}
      className={`collapse transition-all  duration-200 bg-zinc-900 text-white border-box w-full ${
        clicked ? "h-auto " : "h-10 "
      } `}
    >
      <input type="checkbox" checked={clicked} readOnly />
      <div className="collapse-title text-xl rounded-none font-medium px-3 py-1 ">
        <div
          className={`flex justify-between  items-center py-1 border-b-white ${
            clicked ? "border-b" : "border-0 "
          }`}
        >
          <span className="font-normal  text-lg">More About us</span>
          <BsChevronDown
            className={`${
              clicked ? "rotate-180" : "rotate-360"
            } transition-all duration-200`}
          />
        </div>
      </div>
      <div className="collapse-content  text-sm transition-all duration-200 h-72 overflow-y-scroll">
        <p className="py-2 text-[.825rem] tracking-[.6px] whitespace-break-spaces">
          <strong>ebo</strong> is a leading party decoration service provider
          company that caters to all your celebration needs. We specialize in
          creating stunning balloon arrangements for <em>birthday</em>,{" "}
          <em>anniversary</em>,<em>baby shower</em>, <em>first birthday</em>,
          welcome ceremonies, <em>bride to be</em>, <em>groom to be</em>, and
          parties. Decorations for any occasion be it <em>birthday party</em> or{" "}
          <em>bachelor party</em> decoration, <strong>ebo</strong> has quality
          decoration designs for all events giving your party an aesthetic look
          and adding more glare to it, is always the top-priority.{" "}
          <strong>ebo</strong> aims to provide premium and professional service
          at affordable prices and give the party an elegant look. Working for
          the satisfaction of its customers is always the priority of{" "}
          <strong>ebo</strong>. We are dedicated to helping you plan and execute
          your perfect event, whether it's a wedding, birthday party, corporate
          event, or any other special occasion. Our platform allows you to
          browse and book a wide variety of decoration services, lighting, and
          more. With our easy-to-use booking system, you can select the services
          you need, customize them to your liking, and book them all in one
          place. Thinking of decorating your place for any of the occasions
          like, <em>Birthday party</em>, baby shower, romantic dinner date,
          candle light dinner or anniversary celebration, <strong>ebo</strong>{" "}
          is the website you must visit to get a wide variety of decorations and
          get services at affordable rates. <em>Birthday Decoration</em>:
          Birthdays are one of the most special occasions that are celebrated by
          people all over the world. Whether you're planning a small, intimate
          gathering or a grand birthday bash, <strong>ebo</strong> has got you
          covered. We offer a wide range of birthday balloon decoration services
          to suit all budgets and tastes. Our team of experienced professionals
          will work with you to create a custom balloon decoration plan that
          matches your theme and style. From balloon arches and balloon columns
          to balloon bouquets and centrepieces, we can create any design that
          you desire. Our creative team can also add personalized touches such
          as photos, names, and messages to the balloons to make your
          celebration even more memorable. <em>Anniversary Decoration</em>:
          Anniversaries are a time to celebrate love and commitment. Whether
          you're celebrating a milestone anniversary or just want to surprise
          your loved one, <strong>ebo</strong> can help you create a beautiful
          balloon decoration for your special day. We offer a wide range of
          anniversary balloon decoration services to suit all budgets and
          tastes. Our team of experienced professionals will work with you to
          create a custom balloon decoration plan that matches your theme and
          style. From balloon arches and balloon columns to balloon gates, we
          can create any design that you desire. <em>Baby Shower</em>: Welcoming
          a new life into the world is a special occasion that deserves to be
          celebrated. <strong>ebo</strong> offers a wide range of balloon
          decoration services for baby showers to make your event memorable. Our
          team of experienced professionals will work with you to create a
          custom balloon decoration plan that matches your theme and style. From
          balloon arches and balloon columns to balloon bouquets and balloon
          gates we can create any design that you desire. We can also create
          gender-specific balloon arrangements or neutral designs if you prefer.
          Our creative team can also add personalized touches such as photos,
          names, and messages to the balloons to make your celebration even more
          special. <em>First Birthday</em>: A child's first birthday is a
          special milestone that deserves to be celebrated in a memorable way.{" "}
          <strong>ebo</strong> offers a wide range of balloon decoration
          services for first birthdays to make your child's celebration
          unforgettable. Our team of experienced professionals will work with
          you to create a custom balloon decoration plan that matches your theme
          and style. <em>Welcome Ceremony</em>: A welcome ceremony is a special
          event that celebrates a new beginning or a new phase of life.{" "}
          <strong>ebo</strong> offers a wide range of balloon decoration
          services for welcome ceremonies to make your event memorable. Our team
          of experienced professionals will work with you to create a custom
          balloon decoration plan that matches your theme and style.{" "}
          <em>Parties</em>: <strong>ebo</strong> offers a wide range of balloon
          decoration services for all types of parties, private parties. Our
          team of experienced professionals will work with you to create a
          custom balloon decoration plan that matches your theme and style. From
          balloon arches and balloon columns to balloon bouquets and balloon
          gates, we can create any design that you desire. At our platform, we
          prioritize customer satisfaction and are dedicated to providing a
          seamless and stress-free experience. We are confident that our
          decoration services will exceed your expectations and make your event
          unforgettable. Ebo is the most regarded decoration service website. We
          use high-quality materials and pay close attention to every aspect of
          the decor, from the colour scheme to ensure that your experience is
          perfect in every way. With our easy-to-use online booking system, you
          can browse our decor options, customize your selections, and book your
          decor all in one place. Our team is committed to providing exceptional
          service and ensuring that your special occasion is unforgettable.
          Thank you for choosing our online decoration service booking platform,
          and we look forward to helping you celebrate with ease!
        </p>
      </div>
    </div>
  );
}

export default FootMoreAboutUs;
