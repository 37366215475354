import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross1 } from "react-icons/rx";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
import { MdAlternateEmail } from "react-icons/md";
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BiCopy } from "react-icons/bi";

export default function ShareOnSocialMediaModal1({
  universalModalsRenderInfo,
  setUniversalModalsRenderInfo,
}) {
  return (
    <AnimatePresence>
      {universalModalsRenderInfo.shareModal?.isRender && (
        <Modal
          states={universalModalsRenderInfo}
          url={universalModalsRenderInfo.shareModal.url}
          setStates={setUniversalModalsRenderInfo}
        />
      )}
    </AnimatePresence>
  );
}
const Modal = ({ states, setStates, url }) => {
  const handleClose = () => {
    setStates((p) => ({
      ...p,
      shareModal: {
        isRender: false,
        url: "",
      },
    }));
  };

  return (
    <div className="fixed right-0 top-0 left-0 bottom-0 flex items-end justify-end md:justify-center md:items-center z-[999]">
      <div
        onClick={handleClose}
        className="fixed right-0 top-0 left-0 bottom-0 z-[1] bg-[#00000092]"
      ></div>
      <motion.div
        initial={{ translateY: 500 }}
        animate={{ translateY: 0 }}
        exit={{ translateY: 500 }}
        transition={{ duration: 0.2, ease: "linear" }}
        className="w-full  md:w-[30rem]  rounded-t-lg md:rounded-lg overflow-hidden min-h-[5rem] text-[.875rem] relative z-[2] flex flex-col items-center  bg-[white]  "
      >
        <div className="flex w-full items-center justify-between bg-[white] p-4  text-[1rem] font-[500] ">
          <div>Share with</div>
          <RxCross1 onClick={handleClose} className="text-[1rem]" />
        </div>
        <div className=" mt-2 w-full flex gap-4 px-4 flex-nowrap  scroll-bar-remove overflow-scroll items-center">
          <WhatsappShareButton url={url}>
            <div className="flex items-center flex-col  gap-1 font-[500] ">
              <div className=" w-[3rem] h-[3rem] text-[1.125rem] flex items-center justify-center bg-blue-50 rounded-full ">
                <FaWhatsapp />
              </div>
              <div>Whatsapp</div>
            </div>
          </WhatsappShareButton>
          <FacebookShareButton url={url}>
            <div className="flex items-center flex-col  gap-1 font-[500] ">
              <div className=" w-[3rem] h-[3rem] text-[1.125rem] flex items-center justify-center bg-blue-50 rounded-full ">
                <FaFacebookF />
              </div>
              <div>Facebook</div>
            </div>
          </FacebookShareButton>
          <FacebookMessengerShareButton url={url}>
            <div className="flex items-center flex-col  gap-1 font-[500] ">
              <div className=" w-[3rem] h-[3rem] text-[1.125rem] flex items-center justify-center bg-blue-50 rounded-full ">
                <FaFacebookMessenger />
              </div>
              <div>Messenger</div>
            </div>
          </FacebookMessengerShareButton>
          <TwitterShareButton url={url}>
            <div className="flex items-center flex-col  gap-1 font-[500] ">
              <div className=" w-[3rem] h-[3rem] text-[1.125rem] flex items-center justify-center bg-blue-50 rounded-full ">
                <FaXTwitter />
              </div>
              <div>Twitter</div>
            </div>
          </TwitterShareButton>
          <EmailShareButton url={url}>
            <div className="flex items-center flex-col  gap-1 font-[500] ">
              <div className=" w-[3rem] h-[3rem] text-[1.125rem] flex items-center justify-center bg-blue-50 rounded-full ">
                <MdAlternateEmail />
              </div>
              <div>Mail</div>
            </div>
          </EmailShareButton>
          <TelegramShareButton url={url}>
            <div className="flex items-center flex-col  gap-1 font-[500] ">
              <div className=" w-[3rem] h-[3rem] text-[1.125rem] flex items-center justify-center bg-blue-50 rounded-full ">
                <FaTelegramPlane />
              </div>
              <div>Telegram</div>
            </div>
          </TelegramShareButton>
        </div>
        <div className=" text-[gray] my-6 text-center w-full  ">
          Or share with link
        </div>
        <div
          onClick={() => {
            window.copyContent(url, "Link copied");
            handleClose();
          }}
          className=" mb-6 rounded-lg cursor-pointer bg-blue-50 justify-between px-4 py-2 text-gray-700 flex items-center gap-2 w-[95%] "
        >
          <div className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap ">
            {url}
          </div>
          <BiCopy className="text-[1.125rem] text-[#2136d4] " />
        </div>
      </motion.div>
    </div>
  );
};
