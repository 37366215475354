import React, { useContext, memo, useRef, useEffect } from "react";
import ProductPreviewContext from "../../context/ProductPreviewContext";
import ClipLoader from "react-spinners/ClipLoader";
import ProductListBanner from "./ProductListBanner";
import ProductCard from "../cards/ProductCard";
import ResponsiveContext from "../../context/ResponsiveContext";
import CustomBanner from "./CustomBanner";
import PageEndBanner from "./PageEndBanner";
import DownloadAppPoster2 from "../downloadApp/DownloadAppPoster2";
import HIWvideoPreview2 from "../videoComp/HIWvideoPreview2";

const ProductList = ({
  products,
  text,
  dataRefresh,
  onProductClickCallBack,
  inPreviewCardCallBack,
}) => {
  const productListCont = useRef(null);
  const loadMoreDiv = useRef(null);

  // -------------------- context --------------------
  const {
    currPage,
    setCurrPage,
    currUserPos,
    setCurrUserPos,
    productListingpageBanners,
  } = useContext(ProductPreviewContext);

  const { screenWidth } = useContext(ResponsiveContext);

  useEffect(() => {
    setTimeout(() => {
      if (productListCont.current && currUserPos > 600)
        productListCont.current.scrollTop = currUserPos;
    }, 1000);
  }, [currUserPos]);

  useEffect(() => {
    !currPage.isLast && currPage.page !== 0 && dataRefresh();
    // eslint-disable-next-line
  }, [currPage]);

  const loadMoreData = async () => {
    setCurrPage((prev) => {
      return {
        page: prev.page + 1,
        isLast: false,
      };
    });
  };

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMoreData();
        }
      });
    },
    {
      threshold: 0,
      // rootMargin: "500px",
    }
  );

  useEffect(() => {
    const container = loadMoreDiv.current;
    setTimeout(() => {
      observer?.observe(container);
    }, 1000);

    return () => {
      if (container) {
        observer?.unobserve(container);
      }
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   // Retrieve Y-axis position from session storage if available and scroll to that position
  //   const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
  //   const yPosition = savedPositions[0];

  //   if (yPosition && productListCont.current) {
  //     productListCont.current.scrollTo(0, yPosition);
  //     const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
  //     savedPositions[0] = 0;
  //     sessionStorage.setItem("YP", JSON.stringify(savedPositions));
  //   }
  // }, []);

  // const onProductClickCallBack = () => {
  //   window.toast("Product clicked");
  //   // Store Y-axis position in session storage
  //   const currentPosition = productListCont.current.scrollTop;
  //   console.log(currentPosition);
  //   const savedPositions = JSON.parse(sessionStorage.getItem("YP")) || [];
  //   savedPositions[0] = currentPosition;
  //   sessionStorage.setItem("YP", JSON.stringify(savedPositions));
  // };

  return (
    <div
      ref={productListCont}
      style={{
        rowGap: "1rem",
      }}
      className="flex w-full flex-wrap  justify-evenly overflow-hidden  "
    >
      {screenWidth > 976 && (
        <div className=" w-[95%] ">
          <ProductListBanner
            productPageTitleName={text}
            productListingpageBanners={productListingpageBanners}
          />
        </div>
      )}
      {products.map((product, index) => (
        <>
          <ProductCard
            index={index}
            key={`${product._id}`}
            inPreviewCardCallBack={inPreviewCardCallBack}
            onProductClickCallBack={onProductClickCallBack}
            setCurrUserPos={setCurrUserPos}
            product={product}
            width={screenWidth > 768 ? "22%" : "47%"}
            min_width={screenWidth > 768 ? "22%" : "47%"}
          />
          {index === (screenWidth > 768 ? 3 : 1) && <HIWvideoPreview2 />}
          {index !== 0 && (index + 1) % (screenWidth > 768 ? 16 : 8) === 0 ? (
            <CustomBanner
              productListingpageBanners={productListingpageBanners}
              idx={index + 1}
            />
          ) : (
            index !== 0 &&
            (index + 1) % (screenWidth > 768 ? 8 : 4) === 0 && (
              <>
                {screenWidth > 768 ? (
                  <CustomBanner
                    productListingpageBanners={productListingpageBanners}
                    idx={index + 1}
                  />
                ) : (
                  <div className="w-full mx-2">
                    <DownloadAppPoster2 />
                  </div>
                )}
              </>
            )
          )}
        </>
      ))}
      <div
        ref={loadMoreDiv}
        className=" relative flex w-[99vw] h-[10rem]  justify-center pt-2"
      >
        {!currPage.isLast ? (
          <ClipLoader size={25} color="#2136d4" />
        ) : (
          <PageEndBanner />
        )}
      </div>
    </div>
  );
};

export default memo(ProductList);
