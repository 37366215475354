import React, { memo, useCallback, useEffect, useState } from "react";
import { useGlobalStore } from "../../context/GlobalStoreContext";
import { v2Api } from "../../api/v2.api";
import { motion } from "framer-motion";
import HIWvideoPreview1 from "./HIWvideoPreview1";
import { useHomePageContext } from "../../context/HomepageContext";

const VIDEO_ASPECT_RATIO = "12/16";

const HIWvideoPreview2 = () => {
  const { storeStates, setStoreStates } = useGlobalStore();
  const { homeInitialData } = useHomePageContext();

  const [videoStates, setVideoStates] = useState({
    videoLoaded: true,
  });
  useEffect(() => {
    if (
      homeInitialData.initialData !== null &&
      storeStates.HIWvideos === null
    ) {
      fetchVideoDetails();
    }

    // eslint-disable-next-line
  }, [homeInitialData.initialData]);

  const EACH_VIDEO_WIDTH = window.innerWidth > 976 ? 100 : 60; // IN PX

  const [states, setStates] = useState({
    isExpanded: false,
  });

  const fetchVideoDetails = useCallback(async () => {
    try {
      const res = await v2Api({
        operation: "getHowItWorksData",
        tags: ["home"],
      });

      if (res.isSuccess) {
        setStoreStates((prev) => ({ ...prev, HIWvideos: res.data }));
      }
    } catch (error) {
      console.error("Error fetching video details", error);
    }
  }, [setStoreStates]);

  if (storeStates.HIWvideos === null) {
    return (
      <div className="w-full px-4 ">
        <div className="w-full h-[8rem] flex-shrink-0 ebo_skeleton rounded-lg "></div>
      </div>
    );
  }

  if (storeStates.HIWvideos?.length === 0) return;

  return (
    <div className="w-full px-4 lg:px-8 overflow-hidden mb-2">
      <motion.div
        initial={{
          height: window.innerWidth > 976 ? "10rem" : "5.75rem",
        }}
        animate={{
          height: states.isExpanded
            ? "auto"
            : window.innerWidth > 976
            ? "10rem"
            : "5.75rem",
        }}
        className="w-full rounded-lg flex overflow-hidden "
      >
        <motion.div
          animate={{
            x: states.isExpanded ? "-100%" : 0,
          }}
          transition={{
            duration: 0.5,
          }}
          className="min-w-full w-full px-4 py-1 flex justify-between bg-gradient-to-r items-center from-blue-200 via-blue-50 to-transparent"
        >
          <div className="text-left font-semibold">
            <div className="text-base lg:text-3xl text-[black]">
              {" "}
              The{" "}
              <span
                style={{ fontFamily: "Josefin Sans, sans-serif" }}
                className="text-[#2136d4] text-lg lg:text-4xl font-extrabold"
              >
                ebo
              </span>{" "}
              way <span className="text-xs lg:text-2xl ml-0.5">🥳</span>
            </div>
            <div className="text-xs lg:text-lg text-gray-600 font-normal -mt-1">
              Celebration made easy{" "}
              <span className="text-[9px] lg:text-xs ">🚀</span>
            </div>
          </div>
          <div className=" flex -space-x-8 lg:-space-x-12 items-center ">
            {storeStates.HIWvideos?.map((video, index) => {
              const videoWidth = EACH_VIDEO_WIDTH - index * 5;
              return (
                <div
                  key={index}
                  onClick={() => {
                    setStates((prev) => ({
                      ...prev,
                      isExpanded: true,
                    }));
                  }}
                  className="relative bg-gray-300 flex-shrink-0 border border-white rounded-md overflow-hidden"
                  style={{
                    // z index : highest to lowest
                    zIndex: storeStates.HIWvideos?.length - index,
                    width: `${videoWidth}px`,
                    aspectRatio: VIDEO_ASPECT_RATIO,
                  }}
                >
                  {" "}
                  {!videoStates.videoLoaded &&
                    !video?.tags?.includes("imageUrl") && (
                      <div className="bg-black absolute inset-0 z-[3] rounded-lg flex-center">
                        <div
                          className="loader w-[1.25rem]"
                          style={{
                            borderTopColor: "white",
                          }}
                        ></div>
                      </div>
                    )}
                  <video
                    src={video?.collapsedVideoUrl}
                    loop
                    muted
                    autoPlay
                    playsInline={true}
                    controls={false}
                    controlsList="nodownload nofullscreen noremoteplayback"
                    className="absolute inset-0 w-full h-full object-cover"
                    onLoadStart={() => {
                      setVideoStates((prev) => ({
                        ...prev,
                        videoLoaded: false,
                      }));
                    }}
                    onLoadedData={() => {
                      setVideoStates((prev) => ({
                        ...prev,
                        videoLoaded: true,
                      }));
                    }}
                  ></video>
                </div>
              );
            })}
          </div>
        </motion.div>
        <motion.div
          animate={{
            x: states.isExpanded ? "-100%" : 0,
          }}
          transition={{
            duration: 0.5,
          }}
          className="min-w-full flex-shrink-0 w-full"
        >
          <HIWvideoPreview1
            isPadding={false}
            handleBack={() => {
              setStates((prev) => ({
                ...prev,
                isExpanded: false,
              }));
            }}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default memo(HIWvideoPreview2);
