import React, { memo } from "react";
import { useContext } from "react";
import ProductPreviewContext from "../../../context/ProductPreviewContext";
import { getSortingObj } from "../../../functions/searchAlgos/searchAlogHelperFunctions";

const SortList = ({ sortParams, setSortParams, closeModal }) => {
  const { resetPagePositions } = useContext(ProductPreviewContext);

  const handleClick = async (sort) => {
    if (sortParams.get("filter")) {
      const filter = JSON.parse(sortParams.get("filter"));
      filter["sort"] = getSortingObj(sort);
      setSortParams({ filter: JSON.stringify(filter) });
    } else {
      setSortParams({
        sort: sort,
      });
    }
    closeModal();
    await resetPagePositions();
  };

  const liststyle = "my-1 py-2 text-[1.01rem] px-3";
  const active =
    "bg-[#E6EDFF] relative after:absolute after:h-full after:w-1 after:bg-[#2136D4] after:left-0 after:top-0 font-medium";
  return (
    <ul className="py-1">
      <li
        className={`${liststyle} ${
          sortParams.get("sort") === "popularity" && active
        } disabled`}
        onClick={() => handleClick("")}
      >
        Popularity
      </li>
      <li
        className={`${liststyle} ${
          sortParams.get("sort") === "latest" && active
        }`}
        onClick={() => handleClick("latest")}
      >
        Latest
      </li>
      <li
        className={`${liststyle} ${
          sortParams.get("sort") === "discount" && active
        }`}
        onClick={() => handleClick("discount")}
      >
        Discount
      </li>
      <li
        className={`${liststyle} ${
          sortParams.get("sort") === "low-to-high" && active
        }`}
        onClick={() => handleClick("low-to-high")}
      >
        Price: Low to High
      </li>
      <li
        className={`${liststyle} ${
          sortParams.get("sort") === "high-to-low" && active
        }`}
        onClick={() => handleClick("high-to-low")}
      >
        Price: High to Low
      </li>
    </ul>
  );
};

export default memo(SortList);
