import React, { memo } from "react";

function FooterBottom() {
  return (
    <div className="text-center text-[12.8px] w-full bg-[#F1F6FF] pb-2">
      <p>© ebo Solutions Pvt. Ltd. All Rights Reserved.</p>
      <p> Office No. 273, Plot No. 19, Sector - 19D, Satra Plaza, Vashi,</p>
      <p> Mumbai, Maharashtra, 400705</p>
    </div>
  );
}

export default memo(FooterBottom);
