import React, { useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import CompRenderingContext from "../../context/CompRenderingContext";

export default function RewardBoxDetail({ name, isClicked }) {
  const { setBoxInfo } = useContext(CompRenderingContext);
  const handleClose = () =>
    setBoxInfo({ isRender: false, name: "", isClicked: null });

  const RewardBoxList = () => {
    return (
      <ul className="text-sm list-disc">
        <li className="mb-2">
          It is the amount earned from the Referral and other Promotional
          Campaigns.
        </li>
        <li className="mb-2">
          This amount may expire after a certain time depending on the
          promotion.
        </li>
        <li className="">
          You can pay upto 5% of your order value through ebo coins
        </li>
      </ul>
    );
  };

  const CreditBoxList = () => {
    return (
      <ul className="text-sm list-disc">
        <li className="mb-2">
          It is the amount that gets credited to your wallet during refunds if
          you have opted for it.
        </li>
        <li className="mb-2">This amount will never expire.</li>
        <li className="">
          You can use the entire amount to pay for your orders.
        </li>
      </ul>
    );
  };

  return (
    <>
      <div className=" flex justify-center items-center  fixed md:top-0 left-0 right-0 bottom-0 z-10 ">
        <div
          onClick={handleClose}
          className="fixed top-0 left-0 right-0 bottom-0  bg-[rgba(65,62,62,0.6)] z-[1]"
        />
        <div className="reward-info bg-white rounded-[10px] w-full md:w-[320px] z-10">
          <div className="bg-[#2136D4] h-10 rounded-t-lg p-[10px] flex  items-center justify-between">
            <p className="text-white text-lg"> ebo {name}s</p>
            <RxCross2
              color="white"
              size={18}
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className="px-6 py-3">
            <p className="font-medium mb-3"> What is {name}?</p>
            {name === "Reward" && <RewardBoxList />}
            {name === "Credit" && <CreditBoxList />}
          </div>
        </div>
      </div>
    </>
  );
}
