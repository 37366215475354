const cities = [
  {
    city: "mumbai",
    locations: [
      { location: "andheri" },
      { location: "kharghar" },
      { location: "cbd-belapur" },
      { location: "seawoods" },
      { location: "nerul" },
      { location: "juinagar" },
      { location: "sanpada" },
      { location: "vashi" },
      { location: "panvel" },
      { location: "mansarova" },
      { location: "khandeshwar" },
      { location: "kamothe" },
      { location: "taloja" },
      { location: "kopar-khairane" },
      { location: "ghansoli" },
      { location: "mahape" },
      { location: "turbhe" },
      { location: "shilphata" },
      { location: "rabale" },
      { location: "airoli" },
      { location: "thane" },
      { location: "thane-west" },
      { location: "kalwa" },
      { location: "dombivli" },
      { location: "kalyan" },
      { location: "bhiwandi" },
      { location: "ulhasnagar" },
      { location: "ambernath" },
      { location: "powai" },
      { location: "dahisar" },
      { location: "juhu" },
      { location: "mira-road" },
      { location: "bandra" },
      { location: "gorai" },
      { location: "kandivali" },
      { location: "borivali" },
      { location: "goregaon" },
      { location: "khar-road" },
      { location: "santacruz" },
      { location: "dahihar" },
      { location: "jogeshwari" },
      { location: "malad" },
      { location: "vile-parle" },
      { location: "dadar" },
      { location: "sion" },
      { location: "wadala" },
      { location: "byculla" },
      { location: "colaba" },
      { location: "parel" },
      { location: "churchgate" },
      { location: "kalbadevi" },
      { location: "kurla" },
      { location: "govandi" },
      { location: "chunabhatti" },
      { location: "gtb-nagar" },
      { location: "tilaknagar" },
      { location: "sewri" },
      { location: "kings-circle" },
      { location: "mahim" },
      { location: "matunga" },
      { location: "vikhroli" },
      { location: "ghatkopar" },
      { location: "mankhurd" },
      { location: "versova" },
      { location: "bhandup" },
      { location: "mulund" },
      { location: "chembur" },
      { location: "trombay" },
      { location: "saki-naka" },
    ],
    lat: 19.076,
    long: 72.8777,
  },

  {
    city: "pune",
    locations: [
      { location: "kothrud" },
      { location: "swargate" },
      { location: "vimannagar" },
      { location: "kalyani-nagar" },
      { location: "kharadi" },
      { location: "baner" },
      { location: "aundh" },
      { location: "hinjewadi" },
      { location: "pimpri" },
      { location: "chinchwad" },
      { location: "wakad" },
      { location: "hadapsar" },
      { location: "katraj" },
      { location: "bavdhan" },
      { location: "dhankawadi" },
      { location: "karve-nagar" },
      { location: "shivaji-nagar" },
      { location: "deccan" },
      { location: "kondhwa" },
      { location: "bhosari" },
      { location: "nigdi" },
      { location: "akurdi" },
      { location: "chakan" },
      { location: "talawade" },
      { location: "moshi" },
      { location: "chikhali" },
    ],
    lat: 18.5204,
    long: 73.8567,
  },
  {
    city: "siliguri",
    locations: [
      { location: "city center" },
      { location: "punjabi-para" },
      { location: "bagdogra" },
      { location: "ashram-para" },
      { location: "dabgram" },
      { location: "college-para" },
      { location: "subhas-pally" },
      { location: "sevoke-road" },
      { location: "hill-cart-road" },
      { location: "darjeeling-more" },
      { location: "sukna" },
      { location: "salugara" },
      { location: "salbari" },
      { location: "haider-para" },
      { location: "hakim-para" },
      { location: "shaktigarh" },
      { location: "champasari" },
      { location: "checkpost" },
      { location: "matigara" },
      { location: "samarbidi" },
      { location: "eastern-bypass" },
      { location: "ashighar" },
      { location: "iskcon-road" },
      { location: "bhanunagar" },
      { location: "dagapur" },
      { location: "sevoke-more" },
      { location: "singalila" },
      { location: "uttorayon-township" },
      { location: "embee-delight" },
    ],
    lat: 26.7271,
    long: 88.3953,
  },
];

const categories = [
  "Birthday",
  "Anniversary",
  "Wedding",
  "House Warming",
  "Kid's Party",
  "Welcome",
  "Baby Shower",
  "Romantic",
  "Get Together",
  "Pet Party",
  "Bachelorette",
  "Mothers Day",
  "Bouquet",
  "Stage",
  "Store",
  "Premium",
  "Canopy",
];

const getLocation = (location) => {
  let locationData = null;
  let city_ = "";
  cities.forEach((city) => {
    city.locations.forEach((loc) => {
      if (loc.location.trim().toLowerCase() === location.trim().toLowerCase()) {
        city_ = city.city;
        locationData = loc;
      }
    });
  });
  if (!locationData) return null;
  return { locationData, city: city_ };
};

const getCity = (city) => {
  let cityData = null;
  cities.forEach((city_) => {
    if (city_.city.trim().toLowerCase() === city.trim().toLowerCase()) {
      cityData = city_;
    }
  });
  return cityData;
};

module.exports = { cities, getLocation, getCity, categories };
