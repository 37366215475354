import React, { useEffect, useState, memo, useCallback } from "react";
import CategoriesList from "./CatagoriesList";
import SearchResList from "./SearchResList";

function Search({
  handleCloseSearchContainer,
  setSearch,
  srData,
  setSrData,
  productArr,
}) {
  const [srClicked, setSrClicked] = useState(false);

  useEffect(() => {
    // in order to animation the container downward
    setTimeout(function () {
      document
        .querySelector(".search-container")
        .classList.add("search-container-down-animate");
    }, 1);
  }, []);

  const handelCloseOfContainer = useCallback(() => {
    // in order to close the container
    document
      .querySelector(".search-container")
      .classList.remove("search-container-down-animate");
    handleCloseSearchContainer();
    setTimeout(function () {
      setSearch(false);
    }, 200);
    // eslint-disable-next-line
  }, [setSearch]);

  useEffect(() => {
    if (srData.length <= 3) {
      setSrClicked(false);
    } else {
      setSrClicked(true);
    }
  }, [srData]);

  return (
    <>
      <div
        onClick={handelCloseOfContainer}
        className=" fixed top-0 left-0 right-0 bottom-0 bg-white z-[1] md:bg-[#00000062] "
      ></div>
      <div className="search-container">
        {srClicked ? (
          <SearchResList
            handelCloseOfContainer={handelCloseOfContainer}
            srData={srData}
            setSrData={setSrData}
            productArr={productArr}
          />
        ) : (
          <CategoriesList handelCloseOfContainer={handelCloseOfContainer} />
        )}
      </div>
    </>
  );
}

export default memo(Search);
