import axios from "axios";

async function insideHubBoundary(point) {
  const res = await axios.get("/hubBoundaries.json");
  const hubBoundaries = res.data;
  let flag = false;
  let foundInWhichIdx;
  let foundInWhichHub;
  const geoJson = hubBoundaries;
  for (let i = 0; i < geoJson.features.length; i++) {
    const feature = geoJson.features[i];
    const x = point[0],
      y = point[1];
    const vs = feature.geometry.coordinates[0];
    let inside = false;
    for (let j = 0, k = vs.length - 1; j < vs.length; k = j++) {
      const xi = vs[j][0],
        yi = vs[j][1];
      const xj = vs[k][0],
        yj = vs[k][1];

      const isYiGreaterThanY = yi > y;
      const isYjGreaterThanY = yj > y;
      const intersect =
        isYiGreaterThanY !== isYjGreaterThanY &&
        x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    if (inside) {
      flag = true;
      foundInWhichIdx = i;
      foundInWhichHub = feature.properties.hubId;
      break; // Break the loop if point is found inside any feature
    }
  }

  return { isInside: flag, foundInWhichIdx, foundInWhichHub };
}

export { insideHubBoundary };
